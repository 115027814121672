import React from 'react'
import SideBar from './sidebar'

const Layout = ({children}) => {
  return (
    <div className='gap-2 flex flex-row'>
        <SideBar/>
        { children }
    </div>
  )
}

export default Layout