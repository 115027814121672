import React from "react";
import ChatBox from "./gameplay/chatbox";
import ChessBoard from "./chessboard/chessboard";
import { PiHandshakeThin } from "react-icons/pi";
import { FaFontAwesomeFlag } from "react-icons/fa";
import man from '../Images/man1.jpg';
import bman from '../Images/man2.jpg'

import { FaPause } from "react-icons/fa";
import { FaForward } from "react-icons/fa";
import { FaBackward } from "react-icons/fa";
import { CiMenuKebab } from "react-icons/ci";
import { FaFastForward } from "react-icons/fa";
import { FaFastBackward } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { FiSettings } from "react-icons/fi"; 
import { PiHandshakeFill } from "react-icons/pi";
import { FaPlay } from "react-icons/fa";
import { IoPlaySkipForward } from "react-icons/io5";
import { IoPlaySkipBack } from "react-icons/io5";
import Knight from "../Images/playbacknight.png";
import { AiOutlineStop } from "react-icons/ai";

import { IoFlagOutline } from 'react-icons/io5';   // Flag Icon
import { FaChessKing, FaHandshake } from 'react-icons/fa';  // Chess King for Resign, Handshake for Draw
import { FaMedal, FaStopwatch } from 'react-icons/fa';
import King from "../Images/king.png"
import Profile from "../Images/Memoji.png"
const theme = {
  default: {
    light: "bg-gray-200",
    dark: "bg-gray-800",
  },
  blue: {
    light: "bg-blue-200",
    dark: "bg-blue-800",
  },
  green: {
    light: "bg-green-200",
    dark: "bg-green-800",
  },
  red: {
    light: "bg-red-200",
    dark: "bg-red-800",
  },
};

// PlayerProfile Component
const PlayerProfile = () => (
  <div className="flex items-center bg-white rounded-lg w-full p-1 ">
    <div className="flex items-center justify-center w-12 h-12 bg-pink-100 rounded-full">
      <img
        src={Profile}
        alt="Avatar"
        className="w-full h-full object-cover rounded-lg"
      />
    </div>

    <div className="flex flex-col ml-4">
      <div className="flex items-center">
        <span className="bg-red-500 text-white text-xs font-light p-0.5 rounded-sm mr-2">IM</span>
        <h2 className="font-bold text-md">Nishad Khare</h2>
        <span className="text-gray-500 text-md ml-2">(1800)</span>
      </div>

      <div className="flex space-x-2 mt-1">
        <FaMedal className="text-yellow-400 w-5 h-5" />
        <FaMedal className="text-blue-500 w-5 h-5" />
        <FaMedal className="text-gray-400 w-5 h-5" />
      </div>
    </div>

    <div className="flex items-center ml-auto space-x-4 h-12">
      <div className="bg-gray-200 w-24 md:w-32 rounded-lg flex justify-end">
        <div className="bg-white w-16 md:w-20 rounded-lg shadow flex items-center justify-center text-md font-sm py-2 gap-2">
          <FaStopwatch className="text-black w-5 h-5" />
          <p>00:32</p>
        </div>
      </div>
    </div>
  </div>
);

// MenuButtons Component
const MenuButtons = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [actionType, setActionType] = useState("");

  const triggerAction = (type) => {
    setActionType(type);
    setShowOverlay(true);
  };

  const confirmAction = () => {
    console.log(`${actionType} confirmed!`);
    closeOverlay();
  };

  const closeOverlay = () => {
    setShowOverlay(false);
    setActionType("");
  };

  const ActionButton = ({ actionName, icon, className }) => (
    <button
      onClick={() => triggerAction(actionName)}
      className={`h-full w-full p-2 ${className} text-white flex items-center justify-center rounded-lg`}
    >
      {icon}
    </button>
  );

  return (
    <div className="p-1 max-h-full max-w-full">
      <div className="flex flex-row gap-2 justify-between items-center h-24 w-full text-black">
        <ActionButton
          actionName="resign"
          icon={<IoFlagOutline size={40} className="text-black" />}
          className="duration-300 border-black shadow rounded-2xl hover:bg-blue-500"
        />
        <ActionButton
          actionName="draw"
          icon={<PiHandshakeFill size={40} className="text-black" />}
          className="duration-300 border-black shadow rounded-2xl hover:bg-blue-500"
        />
        <ActionButton
          actionName="flag"
          icon={<AiOutlineStop size={40} className="text-black" />}
          className="duration-300 border-black shadow rounded-2xl hover:bg-red-500"
        />
      </div>

      {showOverlay && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-16 rounded-3xl flex flex-col gap-2 shadow-lg text-center">
            <p className="text-lg mb-4">
              Are you sure you want to {actionType === 'resign' ? 'resign' : actionType === 'draw' ? 'offer a draw' : 'flag your opponent for cheating'}?
            </p>
            <div className="flex justify-center gap-5">
              <button onClick={closeOverlay} className="px-8 py-2 bg-white text-black shadow rounded-lg hover:bg-gray-300">Cancel</button>
              <button onClick={confirmAction} className="px-8 py-2 bg-black text-white shadow text-lg rounded-lg hover:bg-gray-800">Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// VideoControl Component
const VideoControl = () => (
  <div className="w-full h-32 bg-gray-900 border-[0.5px] border-gray-700 rounded-lg flex flex-col p-1 justify-center">
    <div className="flex justify-end items-start">
      <button className=" w-4 h-4 flex justify-center items-center rounded-full hover:bg-blue-950">
        <CiMenuKebab size={16} />
      </button>
    </div>

    <div className="flex flex-row items-center justify-center gap-5">
      <button className=" w-10 h-10 flex justify-center items-center rounded-full hover:bg-blue-950">
        <FaFastBackward size={18} />
      </button>
      <button className=" w-12 h-12 flex justify-center items-center rounded-full hover:bg-blue-950">
        <FaBackward size={23} />
      </button>
      <button className=" w-12 h-12  hover:bg-blue-950 flex justify-center items-center rounded-md">
        <FaPause size={28} />
      </button>
      <button className=" w-12 h-12 flex justify-center items-center rounded-full hover:bg-blue-950">
        <FaForward size={23} />
      </button>
      <button className=" w-10 h-10 flex justify-center items-center rounded-full hover:bg-blue-950">
        <FaFastForward size={18} />
      </button>
    </div>
  </div>
);

// Settings Component
const Settings = ({ theme, setTheme }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const settingsRef = useRef(null);

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setIsSettingsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col items-center  relative">
      <button onClick={toggleSettings} className="text-gray-600 m-2">
        <FiSettings size={24} />
      </button>

      {isSettingsOpen && (
        <div
          ref={settingsRef}
          className="absolute top-12 right-0 p-3 bg-white shadow-sm rounded-lg z-10 w-32 sm:w-48"
          style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
        >
          <label htmlFor="theme-select" className="text-sm">
            Select Theme:
          </label>
          <select
            id="theme-select"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
          >
            <option value="default">Default</option>
            <option value="blue">Blue</option>
            <option value="green">Green</option>
            <option value="red">Red</option>
          </select>
        </div>
      )}
    </div>
  );
};

// GamePlay Component
const GamePlay = () => {
  const [theme, setTheme] = useState("default");

  return (
    <div className="w-full h-full flex flex-col md:flex-row gap-2 py-2 px-4 ">
      {/* Left Column: Player Profiles and Chessboard */}
      <div className="flex w-full h-full flex-col md:w-auto  gap-2">
        {/* Player Profile on top */}
        <div className="flex-none">
          <PlayerProfile />
        </div>
        
        {/* ChessBoard that grows */}
        <div className="flex-grow">
          <ChessBoard theme={theme} className="w-full h-full" />
        </div>

        {/* Player Profile at bottom */}
        <div className="flex-none">
          <PlayerProfile />
        </div>
      </div>

      {/* Settings section */}
      <div className="h-full flex items-start justify-start">
        <Settings theme={theme} setTheme={setTheme} />
      </div>

      {/* Right Column: ChatBox and MenuButtons */}
      <div className="h-full flex flex-col gap-2">
        <ChatBox />
        <MenuButtons />
      </div>
    </div>
  );
};


export default GamePlay;


// const MusicPlayer = () => {
//   // Set total duration and initialize current time in seconds
//   const totalDuration = 237; // 3 minutes and 57 seconds
//   const [currentTime, setCurrentTime] = useState(117); // Example: Start at 1:57

//   // Function to format time in minutes:seconds
//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = timeInSeconds % 60;
//     return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
//   };

//   // Handle slider change
//   const handleSliderChange = (e) => {
//     setCurrentTime(Number(e.target.value));
//   };

//   return (
//     <div className="flex flex-col items-center justify-center w-full rounded-2xl">

    

//       {/* Card Container */}
//       <div className="bg-yellow-400 shadow rounded-2xl w-full max-h-full p-6 relative ">
//         {/* Icon and Title */}
//         <div className="flex items-center justify-center space-x-4">
//           <div className="absolute -top-7 left-5">
//             <img
//               src={King}
//               alt="Knight Icon"
//               className="w-24 h-32 object-contain"
//             />
//           </div>
//           <h1 className="text-3xl font-bold text-gray-900">
//             Game <span className="font-light">Control</span>
//           </h1>
//         </div>

//         {/* Progress Bar */}
//         <div className="w-full mt-6">
//           <input
//             type="range"
//             className="w-full h-1 rounded-full bg-white accent-black"
//             min="0"
//             max={totalDuration}
//             value={currentTime}
//             onChange={handleSliderChange} // Update slider value
//           />
//           <div className="flex justify-between text-xs text-gray-800 font-semibold mt-1">
//             <span>{formatTime(currentTime)}</span>
//             <span>{formatTime(totalDuration)}</span>
//           </div>
//         </div>

//         {/* Control Buttons */}
//         <div className="flex space-x-6 justify-center items-center w-full mt-6">
//           <button className="focus:outline-none">
//             <IoPlaySkipBack
//               size={30}
//               className="text-white duration-300 hover:text-black"
//             />
//           </button>
//           <button className="focus:outline-none">
//             <FaPlay size={30} className="text-white duration-300 hover:text-black" />
//           </button>
//           <button className="focus:outline-none">
//             <IoPlaySkipForward
//               size={30}
//               className="text-white duration-300 hover:text-black"
//             />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };