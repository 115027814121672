import React, { useState } from "react";
import { Search, ChevronDown, ChevronUp, Edit2 } from 'lucide-react';

function TransactionTable() {
  const [sortColumn, setSortColumn] = useState('date')
  const [sortDirection, setSortDirection] = useState('desc')
  const [searchTerm, setSearchTerm] = useState('')

  const transactions = [
    { id: 1, against: "Prassana", result: "Won", type: "Bullet", amount: 2999, date: '2023-05-01' },
    { id: 2, against: "Microsoft", result: "Lost", type: "Blitz", amount: 1999, date: '2023-05-03' },
    { id: 3, against: "AlphaZero", result: "Draw", type: "Classical", amount: 99, date: '2023-05-05' },
    { id: 4, against: "Magnus Carlsen", result: "Won", type: "Rapid", amount: 149, date: '2023-05-07' },
    { id: 5, against: "Stockfish", result: "Lost", type: "Bullet", amount: 999, date: '2023-05-09' },
    { id: 6, against: "Hikaru Nakamura", result: "Won", type: "Blitz", amount: 599, date: '2023-05-11' },
    { id: 7, against: "Leela Chess Zero", result: "Draw", type: "Classical", amount: 799, date: '2023-05-13' },
  ]

  const sortedTransactions = [...transactions].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1
    if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1
    return 0
  })

  const filteredTransactions = sortedTransactions.filter(
    transaction =>
      transaction.against.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.result.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.type.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  return (
    <div className="bg-white rounded-2xl shadow-sm flex flex-col max-h-full">
      <div className="pt-2 px-6">
        <div className="stick top-0 flex justify-between items-center mb-2">
          <h2 className="text-2xl font-semibold">Recent Transactions</h2>
          <div className=" relative">
            <input
              type="text"
              placeholder="Search transactions"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>
        </div>
      </div>
      <div className="flex-grow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-white">
              <tr className="text-left text-gray-500 border-b">
                {['Against', 'Result', 'Type', 'Amount', 'Date'].map((header) => (
                  <th
                    key={header.toLowerCase()}
                    className="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort(header.toLowerCase())}
                  >
                    <div className="flex items-center">
                      {header}
                      {sortColumn === header.toLowerCase() && (
                        sortDirection === 'asc' ? <ChevronUp className="w-4 h-4 ml-1" /> : <ChevronDown className="w-4 h-4 ml-1" />
                      )}
                    </div>
                  </th>
                ))}
                <th className="px-6 py-3">Actions</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="overflow-y-auto max-h-[calc(100%-3rem)]">
          <table className="w-full">
            <tbody>
              {filteredTransactions.map((transaction) => (
                <tr key={transaction.id} className="border-b last:border-b-0">
                  <td className="px-6 py-4">{transaction.against}</td>
                  <td className="px-6 py-4">
                    <span className={`px-2 py-1 rounded-full text-sm ${
                      transaction.result === 'Won' ? 'bg-green-100 text-green-800' :
                      transaction.result === 'Lost' ? 'bg-red-100 text-red-800' :
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                      {transaction.result}
                    </span>
                  </td>
                  <td className="px-6 py-4">{transaction.type}</td>
                  <td className="px-6 py-4">${transaction.amount.toLocaleString()}</td>
                  <td className="px-6 py-4">{transaction.date}</td>
                  <td className="px-6 py-4">
                    <button className="text-blue-600 hover:text-blue-800">
                      <Edit2 className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TransactionTable;