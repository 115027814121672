import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider } from 'react-router-dom';
import './index.css';
import {NextUIProvider} from "@nextui-org/react";
import { ThemeProvider } from "@material-tailwind/react";
import router from './Route'

import App from './App';
import Main2 from './landing/main2';
// const router = createBrowserRouter([
//   {
//     path:'/',
//     element:<Main2 />
//   },
//   {
//     path:'/App',
//     element:<App/>
//   }
// ]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
     
    
    <NextUIProvider>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </NextUIProvider>
    
  </React.StrictMode>
);

