import { Link } from 'react-router-dom'
import React from 'react'
import { useState } from 'react'
import GameOverlay from './gameconfoverlay'
import { AlertCircle } from 'lucide-react';
import ChessGroup from '../Images/chessgroup.png'
import { FaBolt, FaClock, FaRunning, FaChess } from 'react-icons/fa';

const GameType = () => {
  return (
    <div className="w-full min-h-screen flex flex-col gap-4 p-4">

      <div className="w-full flex h-full flex-col lg:flex-row gap-4">
        <TabSwitcher />
      </div>
    </div>
  );
};

export default GameType;

const TabSwitcher = () => {
  const [activeTab, setActiveTab] = useState(0);

  const priceList = {
    bullet: [160, 180, 200, 220, 160, 180, 200, 220, 160, 180, 200, 220, 160, 180, 200, 220],
    blitz: [149, 200, 400, 520, 160, 180, 200, 220, 160, 180, 200, 220, 160, 180, 200, 220],
    rapid: [160, 180, 200, 220, 320, 160, 180, 200, 220, 160, 180, 200, 220, 160, 180, 200],
    classic: [149, 200, 400, 520, 660, 160, 180, 200, 220, 160, 180, 200, 220, 160, 200, 220],
  };

  const tabs = [
    { title: 'Bullet', prices: priceList.bullet, icon: <FaBolt size={36} /> },
    { title: 'Blitz', prices: priceList.blitz, icon: <FaClock size={36} /> },
    { title: 'Rapid', prices: priceList.rapid, icon: <FaRunning size={36} /> },
    { title: 'Classic', prices: priceList.classic, icon: <FaChess size={36} /> },
  ];

  return (
    <div className="w-full h-full flex flex-col md:flex-row gap-4">
      <div className="w-full md:w-max flex flex-col justify-center gap-2 p-3">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`shadow w-full md:w-52 h-28 md:h-36 rounded-3xl flex flex-col items-start justify-end text-2xl transition-all p-3 font-medium ease-in-out 
              focus:outline-none ${
              activeTab === index
                ? 'bg-gradient-to-br from-amber-300 to-orange-500 text-white shadow'
                : 'bg-white text-black hover:bg-gradient-to-br from-amber-300 to-orange-500 hover:text-white'
            }`}
            onClick={() => setActiveTab(index)}
          >
            <div className="flex flex-col items-start gap-2">
              {tab.icon}
              <span className="mt-2 text-2xl">{tab.title}</span>
            </div>
          </button>
        ))}
      </div>

      <div className="m-4 p-4 max-h-full w-full bg-gray-100 rounded-3xl border-2 border-gray-200 shadow-inner">
        {activeTab !== null && <BetData key={activeTab} prices={tabs[activeTab].prices} />}
      </div>
    </div>
  );
};

const BetData = ({ prices }) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const handleButtonClick = (price) => {
    setSelectedPrice(price);
    setIsOverlayVisible(true);
  };

  const closeOverlay = () => {
    setIsOverlayVisible(false);
  };

  return (
    <div className="flex flex-col items-center gap-5">
      <p className="text-4xl w-full flex justify-center">Bets</p>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
        {prices.map((price) => (
          <button
            key={price}
            onClick={() => handleButtonClick(price)}
            className={`bg-white w-full h-24 rounded-2xl hover:bg-black hover:text-white duration-300 flex items-center justify-center shadow font-medium`}
          >
            <p className="text-xl">₹ {price}</p>
          </button>
        ))}
      </div>

      {isOverlayVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={closeOverlay}>
          <div
            className="bg-white rounded-3xl my-8 overflow-hidden w-11/12 md:w-2/5 h-auto mx-auto shadow-lg text-center flex flex-col"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="relative h-1/3">
              <div className="absolute inset-0">
                <div className="bg-gradient-to-b from-purple-400 to-blue-300 h-full w-full rounded-b-[100%]"></div>
              </div>
              <div className="relative z-10 flex flex-col items-center justify-center h-full">
                <div className="w-32 h-32 md:w-56 md:h-56 flex items-end">
                  <img src={ChessGroup} alt="Chess Group" />
                </div>
              </div>
            </div>

            <div className="px-6 py-4 flex-grow flex flex-col justify-between">
              <div>
                <h1 className="text-5xl font-bold mb-1">₹ {selectedPrice}</h1>
                <p className="text-gray-600 mb-4">Prize pool</p>
                <p className="text-md mb-4">Entry Fee: ₹ {selectedPrice / 2}</p>
                <Link to="/home2/gametype/loadingscreen">
                  <button className="bg-black text-white font-bold py-3 px-10 rounded-xl mb-2 text-2xl shadow hover:bg-blue-gray-900">
                    Play
                  </button>
                </Link>
              </div>

              <div>
                <hr className="border-gray-300 mb-4" />
                <div className="text-xs text-gray-500 space-y-2">
                  <p>In case of a draw, the prize money will be divided.</p>
                  <p>An unstable internet connection can lead to disqualification.</p>
                  <p>Entry Fee Breakdown: (Prize Pool: ₹ {selectedPrice * 0.94}, Platform fee: ₹ {selectedPrice * 0.06})</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


