import React, { useState, useCallback } from 'react';

export default function Profile() {
  const [media, setMedia] = useState({
    banner: null,
    profile: null,
  });

  const handleMediaUpload = useCallback((event, mediaType) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setMedia((prevMedia) => ({
          ...prevMedia,
          [mediaType]: { src: reader.result, type: file.type },
        }));
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const renderUploadButton = (id, label, onChange) => (
    <label htmlFor={id} className="bg-white text-gray-800 px-4 py-2 rounded-lg shadow hover:bg-gray-100 transition cursor-pointer">
      {label}
      <input id={id} type="file" accept="image/*,.gif" className="hidden" onChange={onChange} />
    </label>
  );

  const ratingTypes = [
    { name: 'Rapid', icon: 'M12 2v20M2 12h20', color: 'amber' },
    { name: 'Blitz', icon: 'M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5', color: 'sky' },
    { name: 'Bullet', icon: 'M12 2v4m0 12v4M4.93 4.93l2.83 2.83m8.48 8.48l2.83 2.83', color: 'emerald' },
    { name: 'Classic', icon: 'M12 2L2 7v10l10 5 10-5V7L12 2z', color: 'rose' }
  ];

  return (
    <div className="w-full min-h-screen bg-white text-gray-800">
      {/* Banner Section */}
      <div className="relative h-72 bg-gray-100">
        {media.banner ? (
          <div className="relative w-full h-full group">
            <img src={media.banner.src} alt="Banner" className="w-full h-full object-cover" />
            <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent"></div>
            <div className="absolute top-0 right-0 p-4 opacity-0 group-hover:opacity-100 transition-opacity">
              {renderUploadButton('banner-upload', 'Edit Banner', (e) => handleMediaUpload(e, 'banner'))}
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            {renderUploadButton('banner-upload', 'Upload Banner (Image or GIF)', (e) => handleMediaUpload(e, 'banner'))}
          </div>
        )}
      </div>

      {/* Profile Section */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-3xl shadow-sm p-6 -mt-24 relative z-10">
          <div className="flex flex-col md:flex-row items-center md:items-start mb-6">
            {/* Profile Image */}
            <div className="relative w-32 h-32 mb-4 md:mb-0 md:mr-6 flex-shrink-0 group">
              {media.profile ? (
                <>
                  <img src={media.profile.src} alt="Profile" className="w-full h-full object-cover rounded-full border-4 border-white shadow-lg" />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity">
                    {renderUploadButton('profile-upload', 'Edit', (e) => handleMediaUpload(e, 'profile'))}
                  </div>
                </>
              ) : (
                <div className="w-full h-full bg-gray-300 rounded-full flex items-center justify-center border-4 border-white shadow-lg">
                  {renderUploadButton('profile-upload', 'Upload', (e) => handleMediaUpload(e, 'profile'))}
                </div>
              )}
            </div>

            {/* Profile Info */}
            <div className="text-center md:text-left flex-grow">
              <h1 className="text-4xl font-bold">Magnus Carlsen</h1>
              <p className="text-gray-600">@MagnusCarlsen</p>
              <div className="flex items-center justify-center md:justify-start mt-2 space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
                </svg>
                <span className="text-blue-500 font-semibold text-lg">#1</span>
                <span className="text-gray-600 text-lg">Followers 10,000</span>
              </div>
            </div>
          </div>

          {/* Ratings */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
            {ratingTypes.map((type) => (
              <div key={type.name} className={`bg-white rounded-xl shadow-lg p-6 duration-300  border-t-4 border-${type.color}-500`}>
                <div className="flex flex-col items-center mb-4">
                  <div className={`text-${type.color}-500 mb-2`}>
                    <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d={type.icon} />
                    </svg>
                  </div>
                  <h3 className={`text-xl font-bold text-${type.color}-600 bg-${type.color}-100 rounded-full px-4 py-1 mb-2`}>
                    {type.name}
                  </h3>
                </div>
                <div className="text-center">
                  <div className="text-3xl font-bold text-gray-800">1080</div>
                  <div className={`text-sm text-${type.color}-600 mt-1`}>Current Rating</div>
                </div>
              </div>
            ))}
          </div>

          {/* Stats & Achievements */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-xl font-semibold mb-2">Stats</h3>
              <div className="bg-gray-100 p-4 rounded-3xl">
                <p className="mb-2"><span className="font-semibold">Games Played:</span> 1,024</p>
                <p><span className="font-semibold">Win Rate:</span> 99%</p>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Achievements</h3>
              <div className="bg-gray-100 p-4 rounded-3xl flex flex-wrap gap-2">
                {[...Array(8)].map((_, i) => (
                  <span key={i} className="text-2xl" role="img" aria-label="medal">🏅</span>
                ))}
              </div>
            </div>
          </div>

          {/* Social Media Icons */}
          <div className="mt-6 flex space-x-4 justify-end">
            <a href="#" className="text-pink-500 hover:text-pink-600 transition">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.072 1.688-.072 4.948 0 3.259.013 3.667.072 4.948.2 4.36 2.619 6.78 6.98 6.98 1.281.059 1.688.072 4.947.072 3.258 0 3.667-.013 4.947-.072 4.361-.2 6.78-2.619 6.98-6.98.059-1.281.072-1.689.072-4.948 0-3.259-.013-3.667-.072-4.947-.2-4.362-2.619-6.781-6.98-6.98-1.281-.059-1.688-.072-4.947-.072zm0 5.838c-3.403 0-6.162 2.761-6.162 6.162 0 3.402 2.759 6.162 6.162 6.162s6.162-2.76 6.162-6.162c0-3.401-2.759-6.162-6.162-6.162zm0 10.085c-2.166 0-3.923-1.758-3.923-3.923s1.758-3.923 3.923-3.923 3.923 1.758 3.923 3.923-1.758 3.923-3.923 3.923zm6.406-10.849c-.796 0-1.443.647-1.443 1.443s.647 1.443 1.443 1.443 1.443-.647 1.443-1.443-.647-1.443-1.443-1.443z"/>
              </svg>
            </a>
            {/* Add other social icons here */}
          </div>
        </div>
      </div>
    </div>
  );
}