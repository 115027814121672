import React from 'react'

// const Charts = () => {
//   return (
    
    //     <div class="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
    //     <div class="flex justify-between">
    //         <div>
    //         <h5 class="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">32.4k</h5>
    //         <p class="text-base font-normal text-gray-500 dark:text-gray-400">Users this week</p>
    //         </div>
    //         <div
    //         class="flex items-center px-2.5 py-0.5 text-base font-semibold text-green-500 dark:text-green-500 text-center">
    //         12%
    //         <svg class="w-3 h-3 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
    //             <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13V1m0 0L1 5m4-4 4 4"/>
    //         </svg>
    //         </div>
    //     </div>
    //     <div id="area-chart"></div>
    //     <div class="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
    //         <div class="flex justify-between items-center pt-5">
    //         {/* <!-- Button --> */}
    //         <button
    //             id="dropdownDefaultButton"
    //             data-dropdown-toggle="lastDaysdropdown"
    //             data-dropdown-placement="bottom"
    //             class="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
    //             type="button">
    //             Last 7 days
    //             <svg class="w-2.5 m-2.5 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    //             <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
    //             </svg>
    //         </button>
    //         {/* <!-- Dropdown menu --> */}
    //         <div id="lastDaysdropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
    //             <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
    //                 <li>
    //                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Yesterday</a>
    //                 </li>
    //                 <li>
    //                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Today</a>
    //                 </li>
    //                 <li>
    //                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Last 7 days</a>
    //                 </li>
    //                 <li>
    //                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Last 30 days</a>
    //                 </li>
    //                 <li>
    //                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Last 90 days</a>
    //                 </li>
    //             </ul>
    //         </div>
    //         <a
    //             href="#"
    //             class="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2">
    //             Users Report
    //             <svg class="w-2.5 h-2.5 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
    //             <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
    //             </svg>
    //         </a>
    //         </div>
    //     </div>
    // </div>
  //   const days = ['Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat', 'Sun'];

  // return (
  //   <div className="max-w-sm w-full bg-white rounded-3xl shadow p-6">
  //     <h2 className="text-2xl font-bold mb-2">Perfect Week!</h2>
  //     <p className="text-gray-600 mb-6">Wooah! You're on a 7-day health streak!</p>
      
  //     <div className="flex justify-between mb-8">
  //       {days.map((day) => (
  //         <div key={day} className="flex flex-col items-center">
  //           <div className="w-8 h-8 bg-red-500 rounded-full flex items-center justify-center mb-2">
  //             <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  //               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
  //             </svg>
  //           </div>
  //           <span className="text-xs text-gray-500">{day}</span>
  //         </div>
  //       ))}
  //     </div>
      
  //     <button className="w-full bg-red-500 text-white py-3 rounded-full font-semibold hover:bg-red-600 transition duration-300">
  //       Claim Reward
  //     </button>
  //   </div>
  // )
  
// }
import { Check } from 'lucide-react'

function Charts() {
  const streakDays = 7
  const checkmarks = 5

  return (
    // <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white rounded-3xl shadow p-6 w-full max-w-sm h-full">
        <h1 className="text-3xl font-bold mb-2 text-center">Streak</h1>
        <p className="text-gray-600 text-center mb-6">
          Earn bonus by completing weekly streak
        </p>
        <div className="flex flex-col items-center mb-6">
          <span className="text-6xl font-bold">{streakDays}</span>
          <span className="text-xl text-gray-600">Days</span>
        </div>
        <div className="flex justify-center space-x-2">
          {[...Array(checkmarks)].map((_, index) => (
            <div
              key={index}
              className="w-10 h-10 rounded-full bg-blue-400 flex items-center justify-center"
            >
              <Check className="text-white w-6 h-6" />
            </div>
          ))}
        </div>
      </div>
    // </div>
  )
}

export default Charts