import React from 'react'
import { Link } from 'react-router-dom';
import { IoIosNotificationsOutline } from "react-icons/io";
import free from '../Images/free.svg'
import ai from '../Images/ai.svg'
// import friend from '../Images/friend.svg'
import friend from '../Images/g.svg'
import paid from '../Images/playpaid.svg'
import Charts from './chart';
import { BsCurrencyDollar } from "react-icons/bs";
import LeaderboardImage from "../Images/leaderboardimage2.png"
import { useState, useEffect } from 'react';

import Slider from 'react-slick';

const Home2 = () => {
  return (
    <div className='w-full h-screen flex flex-col mx-5'>
        
        <HomeHeader/>
      
        <div className='max-w-full h-screen flex flex-row justify-between '>
            <div className='max-w-full max-h-full flex flex-col gap-2'>
                {/* <div className='w-full h-56 shadow object-cover bg-white rounded-2xl mb-5 '> */}
                  {/* <img src={headerimage }alt="" /> */}
                  <ChessCarousel />
                {/* </div> */}
                <p className='text-3xl font-medium'>Quick Match</p>
                <div className='flex h-max flex-row gap-4'>
                  <Link to='/home2/gametype'> 
                    <div className='w-52 h-32 rounded-3xl  shadow-xl transition-transform transform hover:scale-105 '>
                        <img src={free} className='object-contain ' />
                    </div>
                  </Link>
                  <div className='shadow-xl w-52 h-32 rounded-3xl  bg-white transition-transform transform hover:scale-105'>
                    <img src={paid} className='object-contain ' />
                    </div>

                  <Link to='/home2/aigameplay'>
                    <div className='shadow-xl w-52 h-32 rounded-3xl  bg-white transition-transform transform hover:scale-105'>
                      <img src={ai} className='object-contain ' />
                    </div>
                  </Link>
                    
                    <div className='shadow-xl w-52 h-32 rounded-3xl bg-white transition-transform transform hover:scale-105'>
                    <img src={friend} className='object-contain' /></div>  
                </div>
             
            </div>
            <div className='max-w-full max-h-full'>
                <SubHomeNav2/>
            </div>
        </div>
        
        
        
    </div>
  )
}

export default Home2

const SubHomeNav2 = () => {
    return (
      <div className='max-w-full h-full flex flex-col gap-4'>
         
          {/* <div className='w-10/12 h-96 bg-white shadow-lg rounded-xl p-3 gap-2'> */}
            {/* <p className='text-2xl'>Statistics</p>
            <Rings move={0.75} exercise={0.66} stand={0.5} /> */}
            <Charts />
            
          {/* </div> */}
            <DailyPuzzle/>
          
          {/* <div className='w-10/12 h-64 bg-white shadow-lg rounded-xl'> */}
            
            {/* <Leaderboard  /> */}
          {/* </div> */}
          
      </div>
    )
  }

  const HomeHeader2 =()=>{
    return (
      <nav className="flex items-center justify-between py-2 px-4 bg-white">
      {/* Left Section: Greeting */}
      <div className="flex items-center">
        <span className="text-3xl font-normal">Hello,</span>
        <span className="text-3xl font-bold ml-1">Nishad</span>
      </div>

      {/* Right Section: Icons */}
      <div className="flex items-center space-x-4">
        {/* Coins Section */}
        <div className="flex items-center space-x-1">
          {/* Icon Placeholder */}
          <img src="/path/to/coin-icon.svg" alt="Coin Icon" className="w-5 h-5" />
          <span className="font-bold text-yellow-500">500</span>
        </div>

        {/* Add Icon */}
        <button className="flex items-center justify-center w-8 h-8 bg-blue-500 rounded-full">
          <img src="/path/to/add-icon.svg" alt="Add Icon" className="w-5 h-5" />
        </button>

        {/* Diamond Icon */}
        <button className="flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full">
          <img src="/path/to/diamond-icon.svg" alt="Diamond Icon" className="w-5 h-5" />
        </button>

        {/* Notification Icon */}
        <button className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
          <img src="/path/to/bell-icon.svg" alt="Notification Icon" className="w-5 h-5" />
        </button>
      </div>
    </nav>
    )
  }


  const HomeHeader = () => {
    return (
      <header className='flex flex-row py-3 justify-between items-center'>
        {/* Welcome message */}
        <div className='flex items-center'>
          <p className="text-4xl text-left">Welcome, <b>Nishad</b></p>
        </div>
  
        {/* Search bar and action items */}
        <div className='flex flex-row gap-4 items-center'>
          {/* Search input */}
          <div className='relative'>
            <input
              type="text"
              id="simple-search"
              className="text-gray-400 text-sm rounded-full pl-10 pr-3 h-10 w-64 bg-gray-200 focus:outline-none"
              placeholder="Search"
              aria-label="Search"
              required
            />
            <svg
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              width="20"
              height="20"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1115.65 7.5a7.5 7.5 0 011.36 9.9z" />
            </svg>
          </div>
  
          {/* Balance display */}
          <div className='flex items-center gap-1 p-2 bg-white rounded-lg shadow'>
            <div className='w-8 h-8 bg-gradient-to-br from-amber-300 to-orange-500 rounded-md flex items-center justify-center'>
              <BsCurrencyDollar className='text-white' size="25" />
            </div>
            <p className='text-lg font-medium'>400</p>
          </div>
  
          {/* Notifications */}
          <button className='w-12 h-12 rounded-lg flex items-center justify-center bg-white shadow'>
            <IoIosNotificationsOutline size="30" aria-label="Notifications" />
          </button>
        </div>
      </header>
    );
  };

  const LeaderboardItem = ({ rank, name, points }) => (
    <div className="flex justify-between items-center py-2">
      <span className="text-gray-700">{rank}. {name}</span>
      <span className="flex items-center">
        +{points}
        <svg className="w-4 h-4 ml-1 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
        </svg>
      </span>
    </div>
  );
  
  const Leaderboard = () => {
    const topUsers = [
      { id: 1, name: "User 1", avatar: "🧑" },
      { id: 2, name: "User 2", avatar: "👦" },
      { id: 3, name: "User 3", avatar: "👧" },
    ];
  
    const leaderboardData = [
      { id: 4, name: "John Doe", points: 25 },
      { id: 5, name: "Sunny Deol", points: 20 },
      { id: 6, name: "Andy Hertz", points: 15 },
    ];
  
    return (
      <div className="w-96 max-h-full rounded-3xl overflow-hidden shadow-lg relative">
        {/* Background image */}
        <img
          src={LeaderboardImage} // Replace with your image URL
          alt="Leaderboard Background"
          className="absolute inset-0 w-full h-full image-fit rounded-3xl"
        />
  
        {/* Semi-transparent overlay to ensure content visibility */}
        <div className="absolute inset-0  rounded-3xl"></div>
  
        <div className="relative px-6 py-4 z-10">
          <h2 className="text-2xl font-bold mb-4 text-white">Leaderboard</h2>
  
          <div className="flex justify-center mb-6 space-x-8">
            {topUsers.map((user, index) => (
              <div key={user.id} className={`w-20 h-20 rounded-full flex items-center justify-center text-4xl ${
                index === 1 ? 'bg-yellow-200 -mt-6' : index === 0 ? 'bg-blue-200 mt-2' : 'bg-pink-200 mt-2'
              }`}>
                {user.avatar}
              </div>
            ))}
          </div>
  
          <div className="bg-white rounded-2xl p-4 max-w-2xl mx-auto">
            {leaderboardData.map((item) => (
              <LeaderboardItem
                key={item.id}
                rank={item.id}
                name={item.name}
                points={item.points}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  const ChessCarousel = () => {
    // Slider settings
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    };
  
    // Array of slide data
    const slides = [
      {
        title: 'Challenge Magnus Carlsen!',
        description: 'Can you defeat the world champion?',
        image: '/path/to/your/chess-image-1.jpg',
        buttonColor: 'text-blue-500',
        bgColor: 'bg-blue-500',
      },
      {
        title: 'Challenge Hikaru Nakamura!',
        description: 'Can you beat the blitz king?',
        image: '/path/to/your/chess-image-2.jpg',
        buttonColor: 'text-green-500',
        bgColor: 'bg-green-500',
      },
      {
        title: 'Challenge Mark Rober!',
        description: 'Can you defeat the former NASA engineer?',
        image: '/path/to/your/chess-image-3.jpg',
        buttonColor: 'text-red-500',
        bgColor: 'bg-red-500',
      },
    ];
  
    return (
      <div className="w-full max-w-4xl mx-auto rounded-2xl">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className="h-56 rounded-2xl overflow-hidden">
              <div className={`${slide.bgColor} h-full relative`}>
                <img
                  src={slide.image}
                  alt={slide.title}
                  className="w-full h-full object-cover"
                  loading="lazy" // Lazy load for better performance
                />
                <div className="absolute inset-0 flex flex-col justify-center items-center p-6 text-white">
                  <h2 className="text-3xl font-bold">{slide.title}</h2>
                  <p className="mt-2 text-lg">{slide.description}</p>
                  <button
                    className={`mt-4 bg-white ${slide.buttonColor} px-4 py-2 rounded-lg font-semibold`}
                  >
                    Play now!
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  function DailyPuzzle() {
    const [time, setTime] = useState({ hours: 10, minutes: 9, seconds: 79 })
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTime(prevTime => {
          if (prevTime.seconds > 0) {
            return { ...prevTime, seconds: prevTime.seconds - 1 }
          } else if (prevTime.minutes > 0) {
            return { ...prevTime, minutes: prevTime.minutes - 1, seconds: 59 }
          } else if (prevTime.hours > 0) {
            return { hours: prevTime.hours - 1, minutes: 59, seconds: 59 }
          }
          return prevTime
        })
      }, 1000)
  
      return () => clearInterval(timer)
    }, [])
  
    return (
      // <div className="flex  max-w-sm mx-auto justify-center">
        <div className="bg-white rounded-3xl shadow p-6 w-full max-w-sm h-full">
          <h1 className="text-black text-3xl md:text-4xl font-bold mb-6 text-center">Daily Puzzle</h1>
          <div className="flex justify-between mb-6 gap-2">
            {['Hr', 'Min', 'Sec'].map((unit, index) => (
              <div key={unit} className="text-center bg-purple-800 rounded-lg p-3 w-24">
                <div className="text-white text-3xl md:text-4xl font-bold">
                  {String(time[Object.keys(time)[index]]).padStart(2, '0')}
                </div>
                <div className="text-purple-300 text-sm">{unit}</div>
              </div>
            ))}
          </div>
          <button className="w-full bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-3 px-4 rounded-full transition duration-300 text-xl">
            Play
          </button>
        </div>
      // </div>
    )
  }