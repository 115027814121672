import React, { useState, useEffect } from "react";
import { Chess } from 'chess.js';
import ChessGameComponent from "../components/ChessGameComponent";
import ControlBox from "../components/Chesscontrolbox";
import { Paperclip, Smile, Send } from 'lucide-react';
import { THEMES } from '../components/Chessboardfinal';
import ChessControlBox from "../components/chesscontrolbox2";
// const Historys = () => {
//   const [isChatVisible, setIsChatVisible] = useState(true);
//   const [message, setMessage] = useState('');
  
//   // Chess game state
//   const [game, setGame] = useState(new Chess());
//   const [currentMoveIndex, setCurrentMoveIndex] = useState(-1);
//   const [moveHistory, setMoveHistory] = useState([]);
//   const [draggedSquare, setDraggedSquare] = useState(null);
//   const [validMoves, setValidMoves] = useState([]);
//   const [lastMove, setLastMove] = useState(null);
//   const [currentTheme, setCurrentTheme] = useState('classic');
//   const [whiteTime, setWhiteTime] = useState(120);
//   const [blackTime, setBlackTime] = useState(120);
//   const [isTimerRunning, setIsTimerRunning] = useState(false);

//   useEffect(() => {
//     let interval;
//     if (isTimerRunning) {
//       interval = setInterval(() => {
//         if (game.turn() === 'w') {
//           setWhiteTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
//         } else {
//           setBlackTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
//         }
//       }, 1000);
//     }
//     return () => clearInterval(interval);
//   }, [isTimerRunning, game]);

//   const handleDragStart = (e, square) => {
//     const piece = game.get(square);
//     if (piece?.color !== game.turn()) {
//       e.preventDefault();
//       return;
//     }

//     setDraggedSquare(square);
//     const moves = game.moves({ square, verbose: true });
//     setValidMoves(moves.map(move => move.to));

//     const dragImage = e.target.cloneNode(true);
//     dragImage.style.width = '64px';
//     dragImage.style.height = '64px';
//     e.dataTransfer.setDragImage(dragImage, 32, 32);
//   };

//   const handleDrop = (targetSquare) => {
//     if (!draggedSquare) return;

//     try {
//       const move = game.move({
//         from: draggedSquare,
//         to: targetSquare,
//         promotion: 'q'
//       });

//       if (move) {
//         setLastMove({ from: draggedSquare, to: targetSquare });
//         setGame(new Chess(game.fen()));
//         setMoveHistory([...moveHistory, move]);
//         setCurrentMoveIndex(moveHistory.length);
//         if (!isTimerRunning) setIsTimerRunning(true);
//       }
//     } catch (error) {
//       console.error('Invalid move:', error);
//     }

//     setDraggedSquare(null);
//     setValidMoves([]);
//   };

//   const navigateMove = (index) => {
//     const newGame = new Chess();
//     for (let i = 0; i <= index; i++) {
//       newGame.move(moveHistory[i]);
//     }
//     setGame(newGame);
//     setCurrentMoveIndex(index);
//   };

//   const resetGame = () => {
//     setGame(new Chess());
//     setMoveHistory([]);
//     setCurrentMoveIndex(-1);
//     setLastMove(null);
//     setWhiteTime(120);
//     setBlackTime(120);
//     setIsTimerRunning(false);
//   };

//   const toggleChatVisibility = () => {
//     setIsChatVisible(!isChatVisible);
//   };

//   const handleSendMessage = (e) => {
//     e.preventDefault();
//     if (message.trim()) {
//       console.log('Sending message:', message);
//       setMessage('');
//     }
//   };

//   const theme = THEMES[currentTheme];

//   return (
//     <div className="flex w-full h-full bg-white p-4 gap-2">
//       {/* Chat Room on the left */}
//       {/* <div className="w-1/4 max-w-sm border rounded-lg bg-white"> */}
//         {/* Chat room content... (unchanged) */}
//         {/* <div className="flex items-center justify-between p-4 border-b">
//           <h1 className="text-lg font-semibold">Chat Room</h1>
//           <button onClick={toggleChatVisibility} className="focus:outline-none" aria-label="Toggle chat visibility">
//             <div className={`w-10 h-6 flex items-center rounded-full p-1 ${isChatVisible ? 'bg-green-400' : 'bg-gray-300'}`}>
//               <div className={`w-4 h-4 bg-white rounded-full shadow-md transform duration-200 ease-in-out ${isChatVisible ? 'translate-x-4' : ''}`}></div>
//             </div>
//           </button>
//         </div> */}

//         {/* {isChatVisible && (
//           <>
//             <div className="p-4 h-80 overflow-y-auto">
//               <div className="space-y-4">
//                 <div className="flex items-start space-x-3">
//                   <img src="/api/placeholder/32" alt="User 1" className="w-10 h-10 rounded-full" />
//                   <div className="bg-gray-100 text-sm text-gray-900 p-3 rounded-lg">Ya. I'll be adding more team members to it.</div>
//                 </div>
//                 <div className="flex justify-end items-start space-x-3">
//                   <div className="bg-black text-sm text-white p-3 rounded-lg">Ok. Should I send it over email as well after filling the details.</div>
//                   <img src="/api/placeholder/32" alt="User 2" className="w-10 h-10 rounded-full" />
//                 </div>
//               </div>
//             </div>

//             <div className="p-4 border-t">
//               <form onSubmit={handleSendMessage} className="flex items-center space-x-2 bg-gray-100 rounded-full p-2">
//                 <input
//                   type="text"
//                   placeholder="Type a new message here"
//                   value={message}
//                   onChange={(e) => setMessage(e.target.value)}
//                   className="flex-grow bg-transparent text-sm focus:outline-none"
//                 />
//                 <button type="button" className="text-gray-500 hover:text-gray-700 focus:outline-none">
//                   <Paperclip size={20} />
//                 </button>
//                 <button type="button" className="text-gray-500 hover:text-gray-700 focus:outline-none">
//                   <Smile size={20} />
//                 </button>
//                 <button type="submit" className="text-blue-500 hover:text-blue-700 focus:outline-none">
//                   <Send size={20} />
//                 </button>
//               </form>
//             </div>
//           </>
//         )}
//       </div> */}

//       {/* Chess Game Components */}
//       {/* <div className="flex items-center justify-center gap-4"> */}
//       {/* <div className="max-w-full max-h-full p-4 bg-red"> */}
//       <ChessGameComponent 
//           game={game}
//           theme={theme}
//           validMoves={validMoves}
//           lastMove={lastMove}
//           handleDragStart={handleDragStart}
//           handleDrop={handleDrop}
//         />
        
//       {/* </div> */}
        
//         {/* <ControlBox 
//           blackTime={blackTime}
//           whiteTime={whiteTime}
//           theme={theme}
//           currentMoveIndex={currentMoveIndex}
//           moveHistory={moveHistory}
//           navigateMove={navigateMove}
//           currentTheme={currentTheme}
//           setCurrentTheme={setCurrentTheme}
//           resetGame={resetGame}
//         /> */}
//       {/* </div> */}
//     </div>
//   );
// };

const Historys = ()=>{
  return(
    <ChessControlBox/>
  )
}

export default Historys;