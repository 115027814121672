import React, { useState, useEffect } from 'react';
import { ChevronFirst, ChevronLast, ChevronLeft, ChevronRight, XCircle, User } from 'lucide-react';

const GameAbortOverlay = ({ player }) => (
  <div className="fixed inset-0 bg-black/0 backdrop-blur-0 animate-[overlay-fade-in_500ms_ease_forwards] flex items-center justify-center z-50">
    <style>{`
      @keyframes overlay-fade-in {
        from {
          background-color: rgba(0, 0, 0, 0);
          backdrop-filter: blur(0px);
        }
        to {
          background-color: rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(4px);
        }
      }
      @keyframes modal-slide-in {
        from {
          transform: translateY(20px);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes icon-bounce {
        0% {
          transform: scale(0.3);
          opacity: 0;
        }
        50% {
          transform: scale(1.2);
        }
        70% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
          opacity: 1;
        }
      }
      @keyframes text-fade-in {
        from {
          opacity: 0;
          transform: translateY(10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `}</style>
    <div className="bg-white/80 backdrop-blur-lg rounded-2xl p-8 max-w-md w-full mx-4 shadow-2xl animate-[modal-slide-in_600ms_ease_forwards] border border-white/20">
      <div className="flex flex-col items-center text-center">
        <div className="animate-[icon-bounce_800ms_ease_forwards]">
          <XCircle className="w-20 h-20 text-red-500" />
        </div>
        <div className="animate-[text-fade-in_400ms_ease_forwards_200ms] opacity-0">
          <h2 className="text-3xl font-bold text-gray-900 mt-6 mb-3">Game Aborted</h2>
          <p className="text-lg text-gray-600 mb-6">
            {player} did not make their first move in time
          </p>
        </div>
        <button 
          onClick={() => window.location.reload()}
          className="px-6 py-3 bg-gradient-to-r from-indigo-600 to-indigo-700 text-white rounded-xl font-medium hover:from-indigo-700 hover:to-indigo-800 transition-all duration-300 shadow-lg hover:shadow-xl animate-[text-fade-in_400ms_ease_forwards_400ms] opacity-0"
        >
          Return to Home
        </button>
      </div>
    </div>
  </div>
);

const LoadingBar = ({ totalTime = 15, onComplete, player }) => {
  const [timeLeft, setTimeLeft] = useState(totalTime);
  const [isWarning, setIsWarning] = useState(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      if (timeLeft <= 5) {
        setIsWarning(true);
      }
      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete(player);
    }
  }, [timeLeft, onComplete, player]);

  const progress = ((totalTime - timeLeft) / totalTime) * 100;

  return (
    <div className="w-full bg-gray-800/10 rounded px-3 py-1">
      <div className="flex justify-between items-center text-xs text-gray-600 mb-1 font-medium">
        <span>Time remaining to play first move</span>
        <span className={`${isWarning ? 'text-red-500 font-bold animate-pulse' : ''}`}>
          {String(Math.floor(timeLeft / 60)).padStart(2, '0')}:{String(timeLeft % 60).padStart(2, '0')}
        </span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-3">
        <div
          className={`h-3 rounded-full transition-all duration-1000 ease-linear shadow-lg ${
            isWarning 
              ? 'bg-gradient-to-r from-red-400 to-red-600 animate-pulse'
              : 'bg-gradient-to-r from-green-300 to-green-500'
          }`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

const ControlBox = ({ 
  blackTime = 120, 
  whiteTime = 120, 
  currentMoveIndex = 0, 
  moveHistory = [], 
  navigateMove, 
  blackPlayer = "Nishad Khare",
  whitePlayer = "Angela White",
  blackPlayerImage = "",
  whitePlayerImage = "",
  isGameOver = false,
  onResign,
  onDrawOffer,
  currentPlayer = 'white'
}) => {
  const [gameStarted, setGameStarted] = useState(false);
  const [isBlackFirstMovePlayed, setIsBlackFirstMovePlayed] = useState(false);
  const [isWhiteFirstMovePlayed, setIsWhiteFirstMovePlayed] = useState(false);
  const [remainingBlackTime, setRemainingBlackTime] = useState(blackTime);
  const [remainingWhiteTime, setRemainingWhiteTime] = useState(whiteTime);
  const [showAbortOverlay, setShowAbortOverlay] = useState(false);
  const [abortingPlayer, setAbortingPlayer] = useState(null);

  const handleGameAbort = (player) => {
    setAbortingPlayer(player);
    setShowAbortOverlay(true);
  };

  // Determine if game controls should be enabled
  const areControlsEnabled = moveHistory.length > 0;

  useEffect(() => {
    let timer;
    if (gameStarted && !isGameOver && !showAbortOverlay) {
      timer = setInterval(() => {
        if (currentPlayer === 'white') {
          setRemainingWhiteTime((prevTime) => Math.max(prevTime - 1, 0));
        } else {
          setRemainingBlackTime((prevTime) => Math.max(prevTime - 1, 0));
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [gameStarted, currentPlayer, isGameOver, showAbortOverlay]);

  useEffect(() => {
    if (moveHistory.length > 0) {
      setIsWhiteFirstMovePlayed(true);
      setGameStarted(true);
    }
    if (moveHistory.length > 1) {
      setIsBlackFirstMovePlayed(true);
    }
  }, [moveHistory]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getTimerClass = (playerColor) => {
    if (!gameStarted) return 'bg-gradient-to-r from-gray-800 to-gray-900 text-white'; 
    const isActive = currentPlayer === playerColor;
    return isActive 
      ? 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white shadow-lg' 
      : 'bg-gradient-to-r from-gray-200 to-gray-300 text-gray-700';
  };

  const getControlButtonStyles = (isEnabled) => {
    const baseStyles = "flex-1 text-center py-4 font-medium transition-colors";
    const enabledStyles = "text-gray-700 hover:bg-red-50 hover:text-red-600";
    const disabledStyles = "text-gray-400 cursor-not-allowed bg-gray-50";
    return `${baseStyles} ${isEnabled ? enabledStyles : disabledStyles}`;
  };

  const PlayerInfo = ({ name, rating, image, isActive }) => (
    <div className="flex justify-between items-center bg-gradient-to-r from-gray-50 to-gray-100 p-2 border-b rounded-tr-xl">
      <div className="flex items-center gap-3">
        <div className={`w-8 h-8 rounded-full overflow-hidden border-2 ${
          isActive ? 'border-indigo-500 shadow-lg' : 'border-gray-200'
        }`}>
          {image ? (
            <img 
              src={image} 
              alt={`${name}'s profile`} 
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gray-100 flex items-center justify-center">
              <User size={20} className="text-gray-400" />
            </div>
          )}
        </div>
        <div className="font-semibold text-gray-800">{name}</div>
      </div>
      <div className="px-3 py-1 bg-gray-200 rounded-full text-sm font-medium text-gray-700">
        {rating}
      </div>
    </div>
  );

  return (
    <>
      {showAbortOverlay && <GameAbortOverlay player={abortingPlayer} />}
      
      <div className="w-full max-w-sm min-h-[600px] h-[80vh] max-h-[900px] bg-white rounded-xl overflow-hidden flex flex-col">
        {/* Black's Timer */}
        <div className={`flex w-36 justify-center items-center p-2 ${getTimerClass('black')} rounded-t-xl transition-all duration-300`}>
          <div className="text-2xl font-bold font-mono">{formatTime(remainingBlackTime)}</div>
        </div>

        {/* Black Player Info */}
        <PlayerInfo 
          name={blackPlayer} 
          rating={902} 
          image={blackPlayerImage}
          isActive={currentPlayer === 'black'}
        />

        {/* Black's Loading Bar */}
        {!isBlackFirstMovePlayed && currentPlayer === 'black' && moveHistory.length === 1 && (
          <LoadingBar 
            totalTime={15} 
            onComplete={() => handleGameAbort(blackPlayer)} 
            player={blackPlayer}
          />
        )}

        {/* Move List and Controls */}
        <div className="flex justify-center space-x-3 py-1 bg-white border-b">
          <button onClick={() => navigateMove(-1)} className="p-2 rounded-lg hover:bg-indigo-50 transition-colors">
            <ChevronFirst size={24} className="text-indigo-600" />
          </button>
          <button onClick={() => navigateMove(Math.max(currentMoveIndex - 1, -1))} className="p-2 rounded-lg hover:bg-indigo-50 transition-colors">
            <ChevronLeft size={24} className="text-indigo-600" />
          </button>
          <button onClick={() => navigateMove(Math.min(currentMoveIndex + 1, moveHistory.length - 1))} className="p-2 rounded-lg hover:bg-indigo-50 transition-colors">
            <ChevronRight size={24} className="text-indigo-600" />
          </button>
          <button onClick={() => navigateMove(moveHistory.length - 1)} className="p-2 rounded-lg hover:bg-indigo-50 transition-colors">
            <ChevronLast size={24} className="text-indigo-600" />
          </button>
        </div>

        {/* Move List */}
        <div className="flex-1 min-h-0 bg-gradient-to-b from-gray-50 to-white p-4 overflow-y-auto">
          <table className="w-full text-sm">
            <tbody>
              {moveHistory.reduce((acc, move, index) => {
                if (index % 2 === 0) {
                  acc.push(
                    <tr key={index} className="hover:bg-indigo-50/50 transition-colors">
                      <td className="pr-2 text-gray-400 font-medium">{Math.floor(index / 2) + 1}.</td>
                      <td className={`pr-4 py-1 ${index === currentMoveIndex ? 'font-bold text-indigo-600' : 'text-gray-700'}`}>
                        {move.san}
                      </td>
                      <td className={`py-1 ${index + 1 === currentMoveIndex ? 'font-bold text-indigo-600' : 'text-gray-700'}`}>
                        {moveHistory[index + 1]?.san || ''}
                      </td>
                    </tr>
                  );
                }
                return acc;
              }, [])}
            </tbody>
          </table>
        </div>

        {/* Game Controls */}
        <div className="flex justify-between items-center bg-white border-t">
          <button
            onClick={areControlsEnabled ? onResign : undefined}
            disabled={!areControlsEnabled}
            className={`${getControlButtonStyles(areControlsEnabled)} border-r`}
          >
            Resign
          </button>
          <button
            onClick={areControlsEnabled ? onDrawOffer : undefined}
            disabled={!areControlsEnabled}
            className={getControlButtonStyles(areControlsEnabled)}
          >
            Draw
          </button>
        </div>

        {/* White's Loading Bar */}
        {!isWhiteFirstMovePlayed && currentPlayer === 'white' && !moveHistory.length && (
          <LoadingBar 
            totalTime={15} 
            onComplete={() => handleGameAbort(whitePlayer)} 
            player={whitePlayer}
          />
        )}

        {/* White Player Info */}
        <PlayerInfo 
          name={whitePlayer} 
          rating={902} 
          image={whitePlayerImage}
          isActive={currentPlayer === 'white'}
        />

        {/* White's Timer */}
        <div className={`flex justify-center w-36 items-center p-2 ${getTimerClass('white')} rounded-br-xl transition-all duration-300`}>
          <div className="text-2xl font-bold font-mono">{formatTime(remainingWhiteTime)}</div>
        </div>
      </div>
    </>
  );
};

export default ControlBox;