import React from 'react'
import { Link } from 'react-router-dom';
import { BsGearFill } from 'react-icons/bs';
import { GoHomeFill } from "react-icons/go";
import { BsChatLeftFill } from "react-icons/bs";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { GrHistory } from "react-icons/gr";
import { MdAccountCircle } from "react-icons/md";
import { FaApple } from "react-icons/fa";

const SideBar = () => {
  return (
    <div className="top-0 left-0 h-screen w-16 rounded-r-xl flex flex-col
     bg-gray-900 dark:bg-gray-900 shadow-lg justify-between ">
                    
        {/* <SideBarIcon icon={<FaFire size="28" />} /> */}
        
        <div className='mt-4 p-2'>
          <div className='flex items-center justify-between mb-5'>
            <FaApple size="50"/>
          </div>
          
          <Divider />
          <Link to="/home2"><SideBarIcon icon={<GoHomeFill size="28" />} /></Link>
          {/* <SideBarIcon icon={<GoHomeFill size="28" />} /> */}
          <Divider />
          {/* <SideBarIcon icon={<BsPlus size="32" />} /> */}
          <Link to="/messages"><SideBarIcon icon={<BsChatLeftFill size="20" />} /></Link>
          <Link to="/funds"><SideBarIcon icon={<FaMoneyBillTransfer size="24" />} /></Link>
          {/* <SideBarIcon icon={<BsChatLeftFill size="20" />} />
          <SideBarIcon icon={<FaMoneyBillTransfer size="24" />} /> */}
          {/* <SideBarIcon icon={<FaPuzzlePiece size="24" />} /> */}
          <Link to="/history"><SideBarIcon icon={<GrHistory size="22" />} /></Link>
          {/* <SideBarIcon icon={<GrHistory size="22" />} /> */}
          <Divider />
          <Link to="/settings"><SideBarIcon icon={<BsGearFill size="22" />} /></Link>
          {/* <SideBarIcon icon={<BsGearFill size="22" />} /> */}
        </div>


        <div className='mb-3'>
         <Link to="/profile"> <SideBarIcon icon={<MdAccountCircle size="22" />} /></Link>
        </div>
        
    </div>
  )
}

const SideBarIcon = ({ icon, text = 'tooltip 💡' }) => (
    <div className="sidebar-icon group">
      {icon}
      <span className="sidebar-tooltip group-hover:scale-100">
        {text}
      </span>
    </div>
);
  
  
 const Divider = () => <hr className="sidebar-hr" />;



export default SideBar