import React, { useState } from 'react';
import { Chess } from 'chess.js';
import ControlBox from '../components/Chesscontrolbox';
import ChessChat from '../components/chesschat';
import { THEMES, pieceImages, sounds, files, ranks } from '../components/chessconfig';
// Importing sound
import castleSound from '../Sound/castle.mp3';
import checkSound from '../Sound/move-check.mp3';
import promoteSound from '../Sound/promote.mp3';
import moveSound from '../Sound/move-self.mp3';
import captureSound from '../Sound/capture.mp3';

const ChessBoard = () => {
  const [chess] = useState(new Chess());
  const [board, setBoard] = useState(chess.board());
  const [draggedFrom, setDraggedFrom] = useState(null);
  const [possibleMoves, setPossibleMoves] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState('midnight'); // Default to 'classic'
  const [moveHistory, setMoveHistory] = useState([]);
  const [currentMoveIndex, setCurrentMoveIndex] = useState(-1); // Start before any moves are made
  const [currentPlayer, setCurrentPlayer] = useState('white');

  const theme = THEMES[selectedTheme]; // Get current theme details based on state

  // Handle move after drop
  const playSound = (soundFile) => {
    const audio = new Audio(soundFile);
    audio.play();
  };

  const handleMove = (from, to) => {
    try {
      const move = chess.move({ from, to });

      if (move) {
        setBoard(chess.board());
        setMoveHistory([...moveHistory, move]);
        setCurrentMoveIndex(moveHistory.length); // Set to the latest move
        setPossibleMoves([]);
        setCurrentPlayer(chess.turn() === 'w' ? 'white' : 'black');

        // Play appropriate sounds
        if (move.flags.includes('c')) playSound(captureSound);
        else if (move.flags.includes('k') || move.flags.includes('q')) playSound(castleSound);
        else if (move.flags.includes('p')) playSound(promoteSound);
        else if (chess.inCheck()) playSound(checkSound);
        else playSound(moveSound);
      } else {
        setDraggedFrom(null);
        setPossibleMoves([]);
      }
    } catch (error) {
      console.error("Invalid move: ", { from, to });
      setDraggedFrom(null);
      setPossibleMoves([]);
    }
  };

  // Handle drag start
  const onDragStart = (e, square) => {
    setDraggedFrom(square);
    e.dataTransfer.setData('text/plain', square);
    const moves = chess.moves({ square, verbose: true }).map((move) => move.to);
    setPossibleMoves(moves);
  };

  // Handle drop
  const onDrop = (e, square) => {
    const draggedFrom = e.dataTransfer.getData('text/plain');
    handleMove(draggedFrom, square);
  };

  // Handle theme change
  const handleThemeChange = (e) => {
    setSelectedTheme(e.target.value);
  };

  // Navigate through move history
  const navigateMove = (newIndex) => {
    if (newIndex >= -1 && newIndex < moveHistory.length) {
      setCurrentMoveIndex(newIndex);
      chess.reset();
      for (let i = 0; i <= newIndex; i++) {
        chess.move(moveHistory[i]);
      }
      setBoard(chess.board());
    }
  };

  return (
    <div className="flex flex-row items-center justify-center gap-2 p-3 w-full min-h-screen bg-white ">
      {/* Theme selector */}
      {/* <div className="mb-4">
        <select
          value={selectedTheme}
          onChange={handleThemeChange}
          className="p-2 border rounded"
        >
          {Object.keys(THEMES).map((key) => (
            <option key={key} value={key}>
              {THEMES[key].name}
            </option>
          ))}
        </select>
      </div> */}
      <ChessChat/>

      {/* Chessboard */}
      <div className={`grid aspect-square grid-cols-8 grid-rows-8 
         max-w-full w-[min(95vw,95vh)] min-w-[320px] min-h-[320px] rounded-lg overflow-hidden`}>
        {ranks.map((rank, rankIndex) =>
          files.map((file, fileIndex) => {
            const isLight = (rankIndex + fileIndex) % 2 === 0;
            const square = file + rank;
            const piece = board[rankIndex][fileIndex];
            const isPossibleMove = possibleMoves.includes(square);

            return (
              <div
                key={square}
                className={`relative flex items-center justify-center 
                  ${isLight ? theme.light : theme.dark}
                  ${isPossibleMove ? theme.highlight : ''} 
                  cursor-pointer`}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => onDrop(e, square)}
              >
                {/* Render piece image */}
                {piece && (
                  <img
                    src={pieceImages[piece.color][piece.type]}
                    alt={`${piece.color}${piece.type}`}
                    className="w-11/12 h-11/12"
                    draggable
                    onDragStart={(e) => onDragStart(e, square)}
                  />
                )}

                {/* Add coordinates */}
                {fileIndex === 0 && (
                  <span className="absolute top-1 left-1 text-xs font-bold text-gray-800">
                    {rank}
                  </span>
                )}
                {rankIndex === 7 && (
                  <span className="absolute bottom-1 right-1 text-xs font-bold text-gray-800">
                    {file}
                  </span>
                )}
              </div>
            );
          })
        )}
      </div>

      {/* Control Box */}
      <ControlBox
        blackTime={120}
        whiteTime={120}
        theme={theme}
        currentMoveIndex={currentMoveIndex}
        moveHistory={moveHistory}
        navigateMove={navigateMove}
        currentTheme={selectedTheme}
        setCurrentTheme={setSelectedTheme}
        resetGame={() => chess.reset()}
        currentPlayer={currentPlayer}
      />
    </div>
  );
};

export default ChessBoard;