import React from "react";
import King from '../Images/king.png'
import Queen from '../Images/queen.png'

import Bishop from '../Images/bishop.png'
import Rook from '../Images/rook.png'
import Pawn from '../Images/pawn.png'

const AiGamePlay = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center gap-16">
      <div className="flex flex-col items-start w-full justify-start pl-12">
        <p className="text-5xl font-bold text-gray-800 mb-2">Levels</p>
        <p className="text-lg text-gray-600">Challenge yourself by playing  all the levels</p>
      </div>

      <div className="w-full flex justify-center gap-5 items-center">
        <Card image={Pawn} title="Pawn" subtitle="Basic level" />
        <Card image={Bishop} title="Bishop" subtitle="Intermediate level" />
        <Card image={Rook} title="Rook" subtitle="Advanced level" />
        <Card image={Queen} title="Queen" subtitle="Expert level" />
        <Card image={King} title="King" subtitle="Master level" />
      </div>
    </div>
  );
};

const Card = ({ title, subtitle, image }) => {
  return (
    <div
      className="w-64 h-96 p-4 rounded-2xl shadow 
      transition-transform transform hover:scale-105 hover:bg-gradient-to-r from-orange-500 to-amber-400
       hover:text-white bg-white flex flex-col justify-center items-center"
    >
      <div className="flex justify-start items-start  mb-4">
        {image ? (
          <img src={image} alt={title} className="w-44 h-44" />
        ) : (
          <div className="text-4xl text-gray-300">?</div>
        )}
      </div>
      <h3 className="text-xl font-semibold">{title}</h3>
      <p className="text-sm">{subtitle}</p>
    </div>
  );
};
// const AiGamePlay = () => {
//   return (
//     <div className="w-full flex flex-col items-center justify-center gap-8">
//       {/* <div className="flex flex-col items-start w-full justify-start ">
//           <p className="text-5xl font-semibold">Levels</p>
//           <p className="text-xl font-">Select your level</p>
//         </div> */}

//         <div className="flex flex-col just items-start w-full justify-start ">
//         <p className="text-5xl font-bold text-gray-800 mb-2">Levels</p>
//         <p className="text-lg text-gray-600">Select your level</p>
//       </div>
//       <div className="w-full flex justify-center gap-5 items-center h-m">
        
//       <Card title="Pawn" subtitle="Basic level" />
//         <Card  title="Bishop" subtitle="Intermediate level" />
//         <Card title="Rook" subtitle="Advanced level" />
//         <Card title="Queen" subtitle="Expert level" />
//         <Card image={King} title="King" subtitle="Master level" />

//         {/* <Card
//         title="King"
//         subtitle="Зал Древние времена"
//         isHighlighted={true}
//         image="https://via.placeholder.com/150" // Replace with the actual image URL
//       /> */}
//       </div>
//     </div>
//   );
// };

// const Card = ({ title, subtitle, image, isHighlighted }) => {
//   return (
//     <div
//       className={`w-64 h-96 p-4 rounded-2xl shadow transition-transform transform hover:scale-105 hover:bg-gradient-to-b from-pink-400 to-pink-600 hover:text-white ${
//         isHighlighted ? "bg-white text-white" : "bg-white"
//       } flex flex-col justify-end items-start`}
//     >
//       {isHighlighted && image ? (
//         <div className="image-fit">
//             <img src={image} alt="Highlighted"  />
//         </div>
        
//       ) : (
//         <div className="text-4xl text-gray-300 mb-4">?</div>
//       )}
//       <h3 className="text-xl  font-semibold">{title}</h3>
//       <p className="text-sm">{subtitle}</p>
//     </div>
//   );
// };

export default AiGamePlay;

