import React from "react";
import { BsGearFill } from "react-icons/bs";
import { GoHomeFill } from "react-icons/go";
import { BsChatLeftFill } from "react-icons/bs";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { GrHistory } from "react-icons/gr";
import { MdAccountCircle } from "react-icons/md";
import { FaApple } from "react-icons/fa";
import { BiSolidChess } from "react-icons/bi";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Chat from "./chat";
import GameWrite from "./gamewrite";
import Players from "./players";
import { Tabs, Tab, Card, CardBody } from "@nextui-org/react";
import { useState } from "react";
import Whiteking from '../../Images/whiteking.jpg'
import Blackking from '../../Images/blackking.jpg'
// import Chat from "./chat";
import { FaHandshakeSimple } from "react-icons/fa6";
import { FaFontAwesomeFlag } from "react-icons/fa";



const ChatBox = () => {
 
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
      { title: 'Game', content: <Game />},
      { title: 'Chat', content: <Chat />},
      { title: 'Analysis', content: 'And this is what Tab 3 contains' },
    ];
  
    return (
      <div className=" w-full h-full  max-w-lg  bg-white border-1 shadow rounded-3xl p-3">
        <div className="flex  rounded-t-lg p-2 gap-3 justify-center border-b-1">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`px-10 py-4  font-medium text-sm rounded-md transition-all duration-300 ease-in-out focus:outline-none ${
                activeTab === index
                  ? 'bg-blue-500 text-white shadow'
                  : 'bg-white text-gray-600 hover:bg-gray-200'
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab.title}
            </button>
          ))}
        </div>
        <div className="mt-4 max-h-full p-4  border-1 border-black rounded-lg ">
          {tabs[activeTab].content}
        </div>
      </div>
  )
};



export default ChatBox;

const Game = () => {
  const moves = [
    'e4', 'e5',
    'Nf3', 'Nc6',
    'Bb5', 'a6',
    'Ba4', 'Nf6',
    'O-O', 'Be7',
    'Re1', 'b5',
    'Bb3', 'd6',
    'c3', 'O-O',
    'h3', 'Nb8',
  ];
  return (
    <div className="h-full flex flex-col justify-between">
  {/* Table Header */}
  <div className="flex justify-between font-bold text-center border-b pb-2 mb-2">
    <div className="w-5/12">White</div>
    <div className="w-5/12">Black</div>
  </div>

  {/* Table Content */}
  <div className="flex flex-col gap-2">
    {moves.map((move, index) => {
      // Ensure we're rendering the white and black moves without any space issues
      if (index % 2 === 0) {
        return (
          <div key={index} className="flex justify-between text-center">
            <div className="w-5/12">{move}</div>
            <div className="w-5/12">{moves[index + 1] || ''}</div> {/* Handles cases where black move may not be available */}
          </div>
        );
      }
      return null; // Skip rendering individual black moves since they are handled with the white move
    })}
  </div>
</div>  // </div>
  );
};
