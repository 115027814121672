// Importing black pieces
import blackKing from '../Images/Black_piece/Chess_kdt45.svg';
import blackQueen from '../Images/Black_piece/Chess_qdt45.svg';
import blackRook from '../Images/Black_piece/Chess_rdt45.svg';
import blackBishop from '../Images/Black_piece/Chess_bdt45.svg';
import blackKnight from '../Images/Black_piece/Chess_ndt45.svg';
import blackPawn from '../Images/Black_piece/Chess_pdt45.svg';

// Importing white pieces
import whiteKing from '../Images/White_piece/Chess_klt45.svg';
import whiteQueen from '../Images/White_piece/Chess_qlt45.svg';
import whiteRook from '../Images/White_piece/Chess_rlt45.svg';
import whiteBishop from '../Images/White_piece/Chess_blt45.svg';
import whiteKnight from '../Images/White_piece/Chess_nlt45.svg';
import whitePawn from '../Images/White_piece/Chess_plt45.svg';

// Sound imports
import castleSound from '../Sound/castle.mp3';
import checkSound from '../Sound/move-check.mp3';
import promoteSound from '../Sound/promote.mp3';
import moveSound from '../Sound/move-self.mp3';
import captureSound from '../Sound/capture.mp3';

export const THEMES = {
  'classic': {
    name: 'Classic',
    light: 'bg-[#EEEED2]',
    dark: 'bg-[#769656]',
    highlight: 'bg-[#baca44]',
    border: 'border-gray-400'
  },
  'blue': {
    name: 'Ocean',
    light: 'bg-[#DEE3E6]',
    dark: 'bg-[#5C7C9D]',
    highlight: 'bg-[#8db5d5]',
    border: 'border-[#5C7C9D]'
  },
  'tournament': {
    name: 'Tournament',
    light: 'bg-[#FFFFFF]',
    dark: 'bg-[#58AC8A]',
    highlight: 'bg-[#8dd5b4]',
    border: 'border-[#58AC8A]'
  },
  'coral': {
    name: 'Coral',
    light: 'bg-[#FDE8E8]',
    dark: 'bg-[#E53E3E]',
    highlight: 'bg-red-300',
    border: 'border-red-500'
  },
  'midnight': {
    name: 'Midnight',
    light: 'bg-[#4A5568]',
    dark: 'bg-[#2D3748]',
    highlight: 'bg-gray-500',
    border: 'border-gray-600'
  }
};

export const pieceImages = {
  w: {
    p: whitePawn,
    r: whiteRook,
    n: whiteKnight,
    b: whiteBishop,
    q: whiteQueen,
    k: whiteKing,
  },
  b: {
    p: blackPawn,
    r: blackRook,
    n: blackKnight,
    b: blackBishop,
    q: blackQueen,
    k: blackKing,
  },
};

export const sounds = {
  castle: castleSound,
  check: checkSound,
  promote: promoteSound,
  move: moveSound,
  capture: captureSound
};

export const files = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
export const ranks = ['8', '7', '6', '5', '4', '3', '2', '1'];