import React from 'react'


const themes = {
  default: {
    light: "bg-gray-200",
    dark: "bg-gray-800",
  },
  blue: {
    light: "bg-blue-200",
    dark: "bg-blue-800",
  },
  green: {
    light: "bg-green-200",
    dark: "bg-green-800",
  },
  red: {
    light: "bg-red-200",
    dark: "bg-red-800",
  },
};

const ChessBoard = ({ theme }) => {
  const letters = ["a", "b", "c", "d", "e", "f", "g", "h"];
  const numbers = [8, 7, 6, 5, 4, 3, 2, 1];

  const renderSquares = () => {
    const squares = [];
    const themeColors = themes[theme];
    for (let row = 0; row < 8; row++) {
      for (let col = 0; col < 8; col++) {
        const isDark = (row + col) % 2 === 1;
        const squareColor = isDark ? themeColors.dark : themeColors.light;
        const textColor = isDark ? "text-white" : "text-gray-800";
        const shouldShowNumber = col === 0;
        const shouldShowLetter = row === 7;

        squares.push(
          <div
            key={`${row}-${col}`}
            className={`${squareColor} flex items-center justify-center relative`}
            style={{ aspectRatio: "1 / 1" }} // Keeps squares perfectly square
          >
            {shouldShowNumber && (
              <span className={`absolute top-1 left-1 text-xs font-semibold ${textColor}`}>
                {numbers[row]}
              </span>
            )}
            {shouldShowLetter && (
              <span className={`absolute bottom-1 right-1 text-xs font-semibold ${textColor}`}>
                {letters[col]}
              </span>
            )}
          </div>
        );
      }
    }
    return squares;
  };

  return (
    <div className="flex  items-center w-full h-full"> {/* Center chessboard both horizontally and vertically */}
      <div
        className="grid grid-cols-8 gap-0 rounded-lg overflow-hidden"
        style={{
          width: "min(100vw, 80vh)", // Ensure it fills the width without any gap on the right side
          height: "min(100vw, 80vh)", // Keep the square ratio based on the smaller side
        }}
      >
        {renderSquares()}
      </div>
    </div>
  );
};



export default ChessBoard;
