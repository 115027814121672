import './App.css';
import Navbar from './components/navbar';
import SideBar from './components/sidebar';
import Home from './components/home';
import GamePlay from './components/gameplay';
import ChessBoard from './components/chessboard/chessboard';
import Landing from './landing/landing';
import router from './Route'
import Login  from './components/login/login';
import { Outlet } from 'react-router-dom';
import SignUp from './components/login/signup';
import Home2 from './components/home2';
import Layout from './components/layout';
import Landing2 from './landing/Landingfinal'

function App() {
  return (

    <Layout>
      <div className="w-full flex flex-row h-screen ">
        
          {/* <SideBar /> */}
          {/* <Home /> */}
            
          {/* <Login /> */}
          {/* <Landing2 /> */}
          {/* <Hero /> */}
          {/* <SignUp /> */}
          {/* <Home2/> */}
          {/* <GamePlay /> */}
          <Outlet/>
          
     </div>
    </Layout>
  );
}

export default App;
