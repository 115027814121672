import React, { useState } from 'react'
import { X, Plus, ArrowUpRight, ArrowDownRight, DollarSign, CreditCard, Search } from 'lucide-react'
import TransactionTable from '../components/funds/transactiontable'
export default function Component() {
  const [isAddFundsOpen, setIsAddFundsOpen] = useState(false)

  return (
    <div className="w-full h-full bg-gray-50 p-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className=" max-h-full lg:col-span-2 space-y-4">
            <BalanceCard onAddFunds={() => setIsAddFundsOpen(true)} />
            <StatisticsSection />
            <TransactionTable />
          </div>
          <div className="space-y-4  h-full  max-w-full">
            <ChartCard title="CPM, €" />
            <ChartCard title="Impressions" />
          </div>
        </div>

      {isAddFundsOpen && <AddFundsOverlay onClose={() => setIsAddFundsOpen(false)} />}
    </div>
  )
}

function BalanceCard({ onAddFunds }) {
  return (
    <div className="bg-white rounded-3xl p-5">
      <h2 className="text-xl font-medium mb-1">Balance</h2>
      <p className="text-6xl font-bold mb-5">$1,000,000</p>
      <div className="flex space-x-4">
        <button
          onClick={onAddFunds}
          className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Funds
        </button>
        <button className="flex items-center justify-center px-6 py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors">
          Raise Query
        </button>
      </div>
    </div>
  )
}

function StatisticsSection() {
  const stats = [
    { title: 'Earnings', amount: 69453.12, change: 24, icon: DollarSign, color: 'bg-green-500' },
    { title: 'Deposits', amount: 45678.90, change: 18, icon: ArrowUpRight, color: 'bg-blue-500' },
    { title: 'Withdrawals', amount: 23456.78, change: -12, icon: ArrowDownRight, color: 'bg-red-500' },
  ]

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {stats.map((stat, index) => (
        <StatCard key={index} {...stat} />
      ))}
    </div>
  )
}

function StatCard({ title, amount, change, icon: Icon, color }) {
  return (
    <div className="bg-white rounded-3xl shadow-sm p-6">
      <div className="flex justify-between items-center mb-4">
        <div className={`p-3 rounded-full ${color}`}>
          <Icon className="w-6 h-6 text-white" />
        </div>
        <span className={`text-sm font-medium ${change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
          {change >= 0 ? '+' : ''}{change}%
        </span>
      </div>
      <h3 className="text-lg font-medium text-gray-500 mb-2">{title}</h3>
      <p className="text-3xl font-bold">${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
    </div>
  )
}

function ChartCard({ title }) {
  const quarters = ['Q2', 'Q3', 'Q4', 'Q1', 'Q2']
  const values = [3.97, 6.01, 5.89, 3.82, 6.48]

  return (
    <div className="bg-white rounded-3xl p-6 flex flex-col ">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{title}</h2>
        <div className="bg-green-100 text-green-800 rounded-full px-4 py-1 text-sm">
          2023 ▼
        </div>
      </div>
      <div className="flex-grow flex flex-col justify-end">
        <div className="flex items-end space-x-2 h-full min-h-[100px]">
          {quarters.map((quarter, index) => (
            <div key={index} className="flex flex-col items-center flex-1 min-w-0">
              <div
                className="w-full bg-blue-500 rounded-t-lg transition-all duration-300 ease-in-out"
                style={{ height: `${(values[index] / Math.max(...values)) * 100}%` }}
              ></div>
              <span className="mt-2 font-medium text-sm truncate w-full text-center">
                {values[index]}
              </span>
              <span className="text-xs text-gray-500 truncate w-full text-center">
                {quarter}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function AddFundsOverlay({ onClose }) {
  const [amount, setAmount] = useState(104.16)
  const [autoRecharge, setAutoRecharge] = useState(false)
  const [rechargeAmount, setRechargeAmount] = useState(500)
  const [balanceThreshold, setBalanceThreshold] = useState(100)

  const paymentMethods = [
    { name: 'Bank Card', fee: '4%', icon: '💳' },
    { name: 'UnionPay', fee: '3.9%', icon: '🇨🇳' },
    { name: 'PayPal', fee: '3%', icon: 'P' },
    { name: 'WebMoney', fee: '2%', icon: '🌐' },
    { name: 'WeChat Pay', fee: '1.9%', icon: '💬' },
    { name: 'Bank Card Skrill', fee: '4.5%', icon: '🏦' },
  ]

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl p-8 w-full max-w-md">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Add Funds</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-medium mb-3">Select a payment method</h3>
          <div className="grid grid-cols-3 gap-3">
            {paymentMethods.map((method, index) => (
              <button
                key={index}
                className={`p-3 border rounded-lg text-center ${
                  index === 0 ? 'bg-blue-100 border-blue-500' : 'border-gray-300'
                }`}
              >
                <div className="text-2xl mb-1">{method.icon}</div>
                <div className="text-sm">{method.name}</div>
                <div className="text-xs text-gray-500">{method.fee}</div>
              </button>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium mb-2">Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(parseFloat(e.target.value))}
            className="w-full border rounded-lg p-3"
          />
          <div className="text-sm text-gray-500 mt-2">
            Amount to be credited: ${(amount - 4.16).toFixed(2)}
            <br />
            Service fee: $4.16
          </div>
        </div>

        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={autoRecharge}
            onChange={() => setAutoRecharge(!autoRecharge)}
            className="mr-3"
          />
          <span className="text-sm">
            Auto-recharge my credit card
          </span>
        </div>

        {autoRecharge && (
          <div className="mb-6 pl-7">
            <div className="flex items-center mb-2">
              <span className="text-sm mr-2">Recharge amount:</span>
              <input
                type="number"
                value={rechargeAmount}
                onChange={(e) => setRechargeAmount(parseInt(e.target.value))}
                className="w-24 border rounded p-1"
              />
            </div>
            <div className="flex items-center">
              <span className="text-sm mr-2">Balance threshold:</span>
              <input
                type="number"
                value={balanceThreshold}
                onChange={(e) => setBalanceThreshold(parseInt(e.target.value))}
                className="w-24 border rounded p-1"
              />
            </div>
          </div>
        )}

        <div className="mb-4">
          <label className="flex items-center">
            <input type="checkbox" className="mr-3" />
            <span className="text-sm">I agree with terms and conditions</span>
          </label>
        </div>

        <button className="w-full bg-blue-600 text-white rounded-lg py-3 font-medium hover:bg-blue-700 transition-colors">
          Pay ${amount.toFixed(2)}
        </button>
      </div>
    </div>
  )
}