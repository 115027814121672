import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Funds from './sidebarpages/funds'
import History from './sidebarpages/history'
import Messages from './sidebarpages/messages'
import Profile from './sidebarpages/profile'
import Settings from './sidebarpages/settings'
import GamePlay from "./components/gameplay";
import { Outlet } from "react-router-dom";
import Login from "./components/login/login";
import SignUp from "./components/login/signup";
import Home2 from "./components/home2";
import GameType from "./components/gametype";
import LoadingScreen from "./components/loadingscreen";
import AiGamePlay from "./components/aigameplay";

const router = createBrowserRouter([
    {
        path:'/',
        element:<App />,
        children:[
            {
                path:'/components/login/login',
                element:<Login/>,
                
            },
            {
                path:'/components/login/signup',
                element:<SignUp/>
            },

            
            {
                path:'/home2',
                element:<Outlet />,
                
                children:[
                    {
                        path:'/home2',
                        element:<Home2 /> ,
                       
                    },
                    {
                        path:'/home2/gametype',
                        element:<GameType/> 

                    },
                    {
                        path:'/home2/gametype/loadingscreen',
                        element:<LoadingScreen/>,
                    },
                    {
                        path:'/home2/gametype/loadingscreen/gameplay',
                        element:<GamePlay/>,
                    },
                    {
                        path:'/home2/aigameplay',
                        element:<AiGamePlay/>,
                    }
                   
                ]
            },

            {
                path:'/funds',
                element:<Funds />
            },
            {
                path:'/history',
                element:<History />
            },
            {
                path:'/messages',
                element:<Messages />
            },
            {
                path:'/profile',
                element:<Profile />
            },
            {
                path:'/settings',
                element:<Settings />
            }
            
        ]
    }

    
])

export default router