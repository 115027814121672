import React, { useState } from 'react'
import { Paperclip, Smile, Send, UserCircle2 } from 'lucide-react'

const ChatRoom = () => {
    const [isChatEnabled, setIsChatEnabled] = useState(true)
    const [messages, setMessages] = useState([
      { id: 1, text: "Ya. I'll be adding more team members to it.", sender: 'user1' },
      { id: 2, text: "Ok. Should I send it over email as well after filling the details.", sender: 'user2' },
      { id: 3, text: "Hi David, have you got the project report pdf?", sender: 'user1' },
      { id: 4, text: "NO. I did not get it", sender: 'user2' },
    ])
    const [newMessage, setNewMessage] = useState('')
  
    const handleToggle = () => {
      setIsChatEnabled(!isChatEnabled)
    }
  
    const handleSendMessage = () => {
      if (newMessage.trim() && isChatEnabled) {
        setMessages([...messages, { id: messages.length + 1, text: newMessage, sender: 'user2' }])
        setNewMessage('')
      }
    }
  
    return (
      <div className="w-full max-w-[380px] min-h-[500px] h-[80vh] max-h-[800px] bg-white rounded-3xl shadow-sm border overflow-hidden flex flex-col">
        <div className="p-4 flex justify-between items-center border-b">
          <h1 className="text-xl font-semibold">Chat Room</h1>
          <label className="flex items-center cursor-pointer">
            <div className="relative">
              <input type="checkbox" className="sr-only" checked={isChatEnabled} onChange={handleToggle} />
              <div className={`w-14 h-7 ${isChatEnabled ? 'bg-blue-gray-900' : 'bg-gray-200'} rounded-full transition-colors duration-300 ease-in-out`}></div>
              <div className={`absolute top-0.5 left-0.5 w-6 h-6 bg-white rounded-full shadow transition-transform duration-300 ease-in-out ${isChatEnabled ? 'transform translate-x-7' : ''}`}></div>
            </div>
          </label>
        </div>
        <div className="flex-1 overflow-y-auto p-4 space-y-4 min-h-0">
          {isChatEnabled ? (
            messages.map((message) => (
              <div key={message.id} className={`flex ${message.sender === 'user2' ? 'justify-end' : ''}`}>
                <div className={`flex items-end ${message.sender === 'user2' ? 'flex-row-reverse' : ''}`}>
                  <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200">
                    <UserCircle2 
                      size={24} 
                      className={message.sender === 'user2' ? 'text-blue-500' : 'text-gray-500'}
                    />
                  </div>
                  <div className={`max-w-xs mx-2 p-3 rounded-lg ${message.sender === 'user2' ? 'bg-blue-gray-900 text-white' : 'bg-gray-100'}`}>
                    <p className="text-sm">{message.text}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">Chat is currently disabled</p>
            </div>
          )}
        </div>
        <div className="p-4 border-t">
          <div className="flex items-center bg-gray-100 rounded-full px-4 py-2">
            <input
              type="text"
              placeholder="Type a new message here"
              className="flex-grow bg-transparent outline-none"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              disabled={!isChatEnabled}
            />
            <button className="text-gray-500 hover:text-gray-700 mx-1">
              <Paperclip size={20} />
            </button>
            <button className="text-gray-500 hover:text-gray-700 mx-1">
              <Smile size={20} />
            </button>
            <button
              onClick={handleSendMessage}
              disabled={!isChatEnabled}
              className="text-blue-500 hover:text-blue-700 ml-2"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    )
  }
  
  export default ChatRoom