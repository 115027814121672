// import React from 'react'
// import { Link } from 'react-router-dom'

// const LoadingScreen= () => {
//   return (
//     <div className='flex flex-col justify-center items-center w-full h-full gap-8'>
//     <Link to='/home2/gametype/loadingscreen/gameplay'>
//         <p className='text-2xl font-normal text-black '>Finding a match...</p>
//     </Link>
    
//     <div className="rounded-md h-12 w-12 border-4 border-t-4 border-blue-500 animate-spin "></div>

//   </div>
//   )
// }

// export default LoadingScreen


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoadingScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      
      // navigate('/home2/gametype/loadingscreen/gameplay'); 
    }, 3000); 

    
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className='flex flex-col justify-center items-center w-full h-full gap-8'>
      {/* <p className='text-2xl font-normal text-black'>Finding a match...</p> */}
      {/* <div className="rounded-md h-12 w-12 border-4 border-t-4 border-blue-500 animate-spin"></div> */}
       <TextLoadingBar text="DameChess" duration={5000} fontSize="3rem" strokeWidth={2} />
    </div>
  );
}



const TextLoadingBar = ({ text, duration = 3000, fontSize = '2rem', strokeWidth = 2 }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, duration / 100);

    return () => clearInterval(interval);
  }, [duration]);

  return (
    <div className="relative inline-block">
      <svg className="absolute inset-0 w-full h-full">
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={fontSize}
          fontWeight="bold"
          fill="none"
          stroke="black"
          strokeWidth={strokeWidth}
        >
          {text}
        </text>
      </svg>
      <svg className="relative z-10">
        <defs>
          <linearGradient id="textFill" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset={`${progress}%`} stopColor="blue" />
            <stop offset={`${progress}%`} stopColor="transparent" stopOpacity="0" />
          </linearGradient>
        </defs>
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={fontSize}
          fontWeight="bold"
          fill="url(#textFill)"
        >
          {text}
        </text>
      </svg>
    </div>
  );
};


export default LoadingScreen;