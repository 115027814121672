import { IoMdShare } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { MdRestartAlt } from "react-icons/md";
import { CiPlay1 } from "react-icons/ci";
import { RiHome2Line } from "react-icons/ri";
import { useState } from "react";

import { Link } from "react-router-dom";

import ChessGroup from '../Images/chessgroup.png'
import LandingPage from '../landing/Landingfinal'

function Messages() {
  return (
  //   <div className="bg-white rounded-3xl  my-8 overflow-hidden w-2/5 h-[470px] mx-auto shadow-lg text-center flex flex-col">
  //   <div className="relative h-1/3">
  //     <div className="absolute inset-0">
  //       <div className="bg-gradient-to-b from-purple-400 to-blue-300 h-full w-full rounded-b-[100%]"></div>
  //     </div>
  //     <div className="relative z-10 flex flex-col items-center justify-center h-full">
  //       <div className="w-56 h-56 flex items-end ">
  //         <img src={ChessGroup} alt=""  />
  //       </div>
  //     </div>
  //   </div>
    
  //   <div className="px-6 py-4 flex-grow flex flex-col justify-between">
  //     <div>
  //       <h1 className="text-5xl font-bold mb-1">$47</h1>
  //       <p className="text-gray-600 mb-4">prize pool</p>
        
  //       <p className="text-md mb-4">Entry Fee: $25</p>
  //       <Link to="/home2/gametype/loadingscreen">
  //         <button className="bg-black text-white font-bold py-3 px-10 rounded-xl mb-2 text-2xl shadow hover:bg-blue-gray-900">
  //           Play
  //         </button>
  //       </Link>
       
  //     </div>
      
  //     <div>
  //       <hr className="border-gray-300 mb-4" />
        
  //       <div className="text-xs text-gray-500 space-y-2">
  //         <p>Incase of Draw, the prize money will be divided</p>
  //         <p>An unstable internet connection can lead to disqualification</p>
  //         <p>Entry Fee Breakdown : (Prize Pool: $23.5 + Platform fee: $1.5)</p>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  <LandingPage />
  //   <div className=" w-full flex items-center justify-center">
  //     <div className="bg-white w-2/5 h-[470px] rounded-3xl p-8
  //      mx-auto shadow-lg ">
  //       <div className="flex justify-between items-center mb-6">
  //         <button className="text-gray-500 hover:text-gray-700">
  //             <IoMdShare size={30}/>
  //         </button>
  //         <h2 className="text-2xl font-medium ">Damechess</h2>
  //         <button className="text-gray-500 hover:text-gray-700">
  //           <IoCloseOutline size={30}/>           
  //         </button>
  //       </div>

  //       <div className="text-center mb-8">
  //         <h1 className="text-8xl font-bold text-gradient-to-r from-slate-900 to-slate-600 ">WIN</h1>
  //         <p className="text-gray-700">by Resignation</p>
  //       </div>

  //       <div className="flex justify-center gap-20 mb-8">
  //         <div>
  //           <p className="text-4xl font-bold">
  //             1605 <span className="text-green-500 text-xl">+25</span>
  //           </p>
  //           <p className="text-gray-600">Rapid rating</p>
  //         </div>
  //         <div className="text-right">
  //           <p className="text-4xl font-bold text-green-500">+200</p>
  //           <p className="text-gray-600">Coin credits</p>
  //         </div>
  //       </div>

  //       <div className="flex justify-center gap-28 mb-8">
  //         <div className="text-center">
  //           <span className="inline-block bg-red-500 shadow-md text-white rounded-lg px-3 py-1 text-sm font-semibold mb-1">
  //             5
  //           </span>
  //           <p className="text-gray-600 text-sm">Blunders</p>
  //         </div>
  //         <div className="text-center">
  //           <span className="inline-block bg-orange-500 shadow-md text-white rounded-lg px-3 py-1 text-sm font-semibold mb-1">
  //             5
  //           </span>
  //           <p className="text-gray-600 text-sm">Misses</p>
  //         </div>
  //         <div className="text-center">
  //           <span className="inline-block bg-green-500 shadow-md text-white rounded-lg px-3 py-1 text-sm font-semibold mb-1">
  //             5
  //           </span>
  //           <p className="text-gray-600 text-sm">Great Moves</p>
  //         </div>
  //       </div>

  //       <div className="flex justify-center gap-24">
  //         <button className="flex items-center text-gray-600 hover:text-gray-800 gap-2">
  //           {/* <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-5 w-5 mr-2"
  //             viewBox="0 0 20 20"
  //             fill="currentColor"
  //           >
  //             <path
  //               fillRule="evenodd"
  //               d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1z"
  //               clipRule="evenodd"
  //             />
  //           </svg> */}
  //           <MdRestartAlt size={22}/> 
  //           Rematch
  //         </button>
  //         <button className="flex items-center text-gray-600 hover:text-gray-800 gap-1">
  //           {/* <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-5 w-5 mr-2"
  //             viewBox="0 0 20 20"
  //             fill="currentColor"
  //           >
  //             <path
  //               fillRule="evenodd"
  //               d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
  //               clipRule="evenodd"
  //             />
  //           </svg> */}
  //           <CiPlay1  size={22}/> 
  //           Next Match
  //         </button>
  //         <button className="flex items-center text-gray-600 hover:text-gray-800 gap-2">
  //           {/* <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-5 w-5 mr-2"
  //             viewBox="0 0 20 20"
  //             fill="currentColor"
  //           >
  //             <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
  //           </svg> */}
  //           <RiHome2Line size={22}/> 
  //           Home
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  //   <div className="w-10/12 max-w-md mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
  //   {/* Header with Gradient and Prize Amount */}
  //   <div className="bg-gradient-to-b from-purple-500 to-blue-400 p-8 text-center">
  //     <h1 className="text-6xl font-bold text-white">$47</h1>
  //     <p className="text-white mt-2">prize pool</p>
  //   </div>

  //   {/* Main Content */}
  //   <div className="p-6 text-center">
  //     <p className="text-gray-500 text-lg mb-4">Entry Fee <span className="text-black font-semibold">$25</span></p>
  //     <button className="bg-black text-white font-semibold py-2 px-8 rounded-full text-lg">
  //       Play
  //     </button>
  //   </div>

  //   {/* Footer Notes */}
  //   <div className="px-6 py-4 text-center text-gray-500 text-sm">
  //     <hr className="my-4"/>
  //     <p>In case of Draw, the prize money will be divided</p>
  //     <p>An unstable internet connection can lead to disqualification</p>
  //     <p className="mt-2">Entry Fee Breakdown: (Prize Pool: $23.5 + Platform fee: $1.5)</p>
  //   </div>
  // </div>
  // <div className="bg-gray-50 rounded-3xl overflow-hidden  w-2/5 h-[470px]  shadow-lg text-center">
  //     <div className="relative h-48">
  //       <div className="absolute inset-0">
  //         <div className="bg-gradient-to-b from-purple-400 to-blue-300 h-full w-full rounded-b-[100%]"></div>
  //       </div>
  //       <div className="relative z-10 flex flex-col items-center justify-center h-full">
  //         <h1 className="text-5xl font-bold text-white mb-1">$47</h1>
  //         <p className="text-white text-opacity-80">prize pool</p>
  //       </div>
  //     </div>
      
  //     <div className="px-6 py-6">
  //       <div className="mb-6">
  //         <p className="text-gray-600 mb-1">Entry Fee</p>
  //         <p className="text-3xl font-semibold">$25</p>
  //       </div>
        
  //       <button className="bg-black text-white font-bold py-2 px-8 rounded-full w-full mb-6">
  //         Play
  //       </button>
        
  //       <hr className="border-gray-300 mb-6" />
        
  //       <div className="text-xs text-gray-500 space-y-2">
  //         <p>Incase of Draw, the prize money will be divided</p>
  //         <p>An unstable internet connection can lead to disqualification</p>
  //         <p>Entry Fee Breakdown : (Prize Pool: $23.5 + Platform fee: $1.5)</p>
  //       </div>
  //     </div>
  //   </div>

  );
}


// function Messages(){
//   const [isOpen, setIsOpen] = useState(true);

//   const closeModal = () => {
//     setIsOpen(false);
//   };

//   return (
//     <>
//       {isOpen && (
//         <div
//           className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
//           onClick={closeModal}
//         >
//           <div
//             className="bg-gray-50 rounded-3xl overflow-hidden w-2/5 h-[470px] shadow-lg text-center"
//             onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
//           >
//             <div className="relative h-48">
//               <div className="absolute inset-0">
//                 <div className="bg-gradient-to-b from-purple-400 to-blue-300 h-full w-full rounded-b-[100%]"></div>
//               </div>
//               <div className="relative z-10 flex flex-col items-center justify-center h-full">
//                 <h1 className="text-5xl font-bold text-white mb-1">$47</h1>
//                 <p className="text-white text-opacity-80">prize pool</p>
//               </div>
//             </div>

//             <div className="px-6 py-6">
//               <div className="mb-6">
//                 <p className="text-gray-600 mb-1">Entry Fee</p>
//                 <p className="text-3xl font-semibold">$25</p>
//               </div>

//               <button className="bg-black text-white font-bold py-2 px-8 rounded-full w-full mb-6">
//                 Play
//               </button>

//               <hr className="border-gray-300 mb-6" />

//               <div className="text-xs text-gray-500 space-y-2">
//                 <p>In case of a draw, the prize money will be divided</p>
//                 <p>An unstable internet connection can lead to disqualification</p>
//                 <p>Entry Fee Breakdown: (Prize Pool: $23.5 + Platform fee: $1.5)</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

export default Messages;
