import React from "react";

import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

import { FaPlay } from "react-icons/fa";
import { IoPlaySkipForward } from "react-icons/io5";
import { IoPlaySkipBack } from "react-icons/io5";
import Knight from "../Images/playbacknight.png";
import { Eye, EyeOff } from 'lucide-react'

const History = () => {
  const [isLogin, setIsLogin] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const toggleAuthMode = () => setIsLogin(!isLogin)

  return (
    <div className="flex w-full h-screen p-3 bg-white">
      {/* Left Section */}
      <div className="w-1/2 bg-black text-white rounded-xl p-12 flex flex-col justify-between relative overflow-hidden">
        <div className="z-10">
          <h1 className="text-5xl font-bold leading-tight mb-6">
            Banking That's<br />Always On<br />Your Side
          </h1>
          <p className="text-lg mb-8">
            PrimeBank is a financial platform to manage<br />your business and money.
          </p>
          <div className="bg-[#DCFCE7] text-black p-6 rounded-xl max-w-md">
            <p className="text-sm mb-4">
              PrimeBank is a wonderful app that made me love to manage my finances. I made good use of tracking expenses and establishing financial goals that are easy to follow.
            </p>
            <div className="flex items-center">
              <img src="/placeholder.svg?height=40&width=40" alt="Michael W." className="rounded-full mr-3" />
              <div>
                <p className="font-semibold">Michael W.</p>
                <p className="text-xs">Sales Manager</p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full">
          <svg viewBox="0 0 100 100" preserveAspectRatio="none" className="h-32 w-full text-gray-800 opacity-20">
            <path d="M0 100 C 20 0 50 0 100 100 Z" fill="currentColor" />
          </svg>
        </div>
      </div>

      {/* Right Section */}
      <div className="w-1/2 p-12 flex flex-col justify-start items-center">
        <div className="w-full max-w-md">
          <div className="flex justify-center mb-8">
            <div className="bg-black rounded-full p-4">
              <svg className="w-8 h-8 text-white" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2L1 21h22L12 2zm0 3.516L20.297 19H3.703L12 5.516z"/>
              </svg>
            </div>
          </div>
          <h2 className="text-2xl font-semibold text-center mb-8">PRIME BANK</h2>
          
          {/* New Toggle Button */}
          <div className="flex justify-center mb-8">
            <div className="bg-black rounded-full p-1 flex">
              <button
                className={`px-6 py-2 rounded-full transition-all duration-300 ${
                  !isLogin ? 'bg-white text-black' : 'text-white'
                }`}
                onClick={() => setIsLogin(false)}
              >
                Sign up
              </button>
              <button
                className={`px-6 py-2 rounded-full transition-all duration-300 ${
                  isLogin ? 'bg-white text-black' : 'text-white'
                }`}
                onClick={() => setIsLogin(true)}
              >
                Login
              </button>
            </div>
          </div>

          <p className="text-center text-gray-600 mb-8">Let's {isLogin ? 'Sign You In' : 'Get You Started'}</p>
          <form>
            {!isLogin && (
              <div className="mb-4">
                <input type="text" placeholder="Full Name" className="w-full p-3 border border-gray-300 rounded-md" required />
              </div>
            )}
            <div className="mb-4">
              <input type="email" placeholder="Email address" className="w-full p-3 border border-gray-300 rounded-md" required />
            </div>
            <div className="mb-4 relative">
              <input 
                type={showPassword ? "text" : "password"} 
                placeholder="Password" 
                className="w-full p-3 border border-gray-300 rounded-md" 
                required 
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff className="text-gray-400" size={20} /> : <Eye className="text-gray-400" size={20} />}
              </button>
            </div>
            {isLogin && (
              <div className="text-right mb-4">
                <a href="#" className="text-sm text-gray-600 hover:underline">Forgot password?</a>
              </div>
            )}
            <button type="submit" className="w-full bg-black text-white p-3 rounded-md hover:bg-gray-800 transition duration-300">
              {isLogin ? 'Login' : 'Sign Up'}
            </button>
          </form>
          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600 mb-4">Or {isLogin ? 'login' : 'sign up'} with</p>
            <div className="flex justify-center space-x-4">
              <button className="flex items-center justify-center w-12 h-12 border border-gray-300 rounded-md hover:bg-gray-100 transition duration-300">
                <img src="/placeholder.svg?height=24&width=24" alt="Google" className="w-6 h-6" />
              </button>
              <button className="flex items-center justify-center w-12 h-12 border border-gray-300 rounded-md hover:bg-gray-100 transition duration-300">
                <img src="/placeholder.svg?height=24&width=24" alt="Apple" className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default History;
