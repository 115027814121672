import React from 'react';
import { ChevronFirst, ChevronLast, ChevronLeft, ChevronRight } from 'lucide-react';

const ChessControlBox2 = () => {
  const moves = [
    { white: 'e4', black: 'e5' },
    { white: 'e4', black: 'e5' },
    { white: 'e4', black: 'e5' },
    { white: 'e4', black: 'e5' },
    { white: 'e4', black: 'e5' },
    { white: 'e4', black: 'e5' },
    { white: 'e4', black: 'e5' },
    { white: 'e4', black: 'e5' },
  ];

  const PlayerInfo = () => (
    <div className=" bg-red-500 p-3 rounded-lg flex items-center gap-2">
      <div className="w-8 h-8 bg-gray-200 rounded-lg overflow-hidden">
        <img 
          src="/api/placeholder/32/32" 
          alt="Player avatar" 
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex items-center gap-1 text-white">
        <span className="text-sm font-medium">Nishad Khare</span>
        <span className="text-sm">(1800)</span>
      </div>
      <div className="flex gap-0.5 ml-1">
        <span className="text-sm">🟡</span>
        <span className="text-sm">🟡</span>
        <span className="text-sm">🟡</span>
      </div>
    </div>
  );

  return (
    <div className=" w-full min-h-screen bg-gray-900 flex items-center justify-center p-4">
      <div className="relative w-full max-w-sm">
        {/* Top Timer */}
        <div className="absolute -top-6 left-1/2 -translate-x-1/2 bg-black text-white w-24 py-2 rounded-2xl text-center text-2xl font-medium z-10">
          2:00
        </div>
        
        {/* Main Card */}
        <div className="bg-white rounded-3xl overflow-hidden shadow-lg">
          <div className="p-4 space-y-4">
            <PlayerInfo />
            
            <div className="bg-red-500 rounded-lg p-4">
              <div className="space-y-1.5 text-white">
                {moves.map((move, index) => (
                  <div key={index} className="flex items-center">
                    <span className="w-6">{index + 1}.</span>
                    <span className="w-12">{move.white}</span>
                    <span className="w-12">{move.black}</span>
                  </div>
                ))}
              </div>
              
              <div className="mt-4 bg-gray-700 rounded-lg p-2 flex justify-center gap-8">
                <ChevronFirst className="w-6 h-6 text-white cursor-pointer" />
                <ChevronLeft className="w-6 h-6 text-white cursor-pointer" />
                <ChevronRight className="w-6 h-6 text-white cursor-pointer" />
                <ChevronLast className="w-6 h-6 text-white cursor-pointer" />
              </div>
            </div>

            <div className="flex border-t text-sm">
              <button className="flex-1 py-3 hover:bg-gray-50 border-r">
                Resign
              </button>
              <button className="flex-1 py-3 hover:bg-gray-50">
                Draw
              </button>
            </div>

            <PlayerInfo />
          </div>
        </div>
        
        {/* Bottom Timer */}
        <div className="absolute -bottom-6 left-1/2 -translate-x-1/2 bg-black text-white w-24 py-2 rounded-2xl text-center text-2xl font-medium z-10">
          2:00
        </div>
      </div>
    </div>
  );
};

export default ChessControlBox2;