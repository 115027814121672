import React from 'react'
import {Input, Button} from "@nextui-org/react";
import { FaArrowRight } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { BsTwitterX } from "react-icons/bs";
import { Link } from 'react-router-dom';

const SignUp = () => {
  return (
    // <div >
    //     <div class="w-full max-w-sm p-6 m-auto mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800">
    //     <div class="flex justify-center mx-auto">
    //         <img class="w-auto h-7 sm:h-8" src="https://merakiui.com/images/logo.svg" alt=""/>
    //         <p className='text-3xl font-extralight text-black   '>Damechess.</p>
    //     </div>

    //     <form class="mt-6">
    //         <div className='flex flex-col items-start'>
    //             <label for="username" class="block text-sm text-gray-800 dark:text-gray-200">Phone Number</label>
    //             <input type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
    //         </div>

    //         <div class="mt-4">
    //             <div class="flex items-center justify-between">
    //                 <label for="password" class="block text-sm text-gray-800 dark:text-gray-200">OTP</label>
    //                 <a href="#" class="text-xs text-gray-600 dark:text-gray-400 hover:underline">Resend OTP</a>
    //             </div>

    //             <input type="password" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
    //         </div>

    //         <div class="mt-6">
    //             <button class="w-full px-6 py-2.5 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50">
    //                 Send OTP
    //             </button>
    //         </div>
    //     </form>


    //     <p class="mt-8 text-xs font-light text-center text-gray-400"> Already have an account? <a href="#" class="font-medium text-gray-700 dark:text-gray-200 hover:underline">Log In</a></p>
    // </div>
    // </div>
    <div className='h-screen flex flex-col items-center gap-16  w-full '>
        
            <section className='mt-32'>
                <p className='text-7xl text-white '>DameChess</p>
            </section>
            
            <div className='w-full flex flex-col gap-4 items-center'>
                <p className='text-3xl text-white font-extralight mb-4'>Sign Up</p>

                <Input
                type="email"
                label="Email"
                variant="bordered"
                // defaultValue="junior2nextui.org"
                isInvalid={false}
                errorMessage="Please enter a valid email"
                size='md'
                className="max-w-xs"
                /> 
                <Input
                type="password"
                label="Password"
                variant="bordered"
                // defaultValue="junior2nextui.org"
                isInvalid={false}
                errorMessage="Please enter a valid password"
                size='md'
                className="max-w-xs"
                /> 

                <Button isIconOnly color="success" aria-label="Like" size='lg'>
                    <FaArrowRight />
                </Button>

                <p className="mt-2 text-xs text-center text-gray-400 uppercase dark:text-gray-400 hover:underline">
                    or sign in with Social Media
                </p>
                <section className='flex flex-row gap-3'>
                    <Button isIconOnly className='bg-white' size='md'>
                        <FcGoogle size={30} />
                    </Button>
                    <Button isIconOnly className='bg-white' size='md'>
                        <BsTwitterX size={25} />
                    </Button>
                </section>
                <section>
                    <span class="text-sm text-gray-400 dark:text-gray-200">Already have an account? </span>
                    {/* <a href="#" class=" text-sm font-semibold text-white dark:text-blue-400 hover:underline">Login</a> */}
                    <Link to="/login" className='text-sm font-semibold text-white dark:text-blue-400 hover:underline'>Login</Link>
                </section>
                
            </div>
            

        
        </div>
  )
}

export default SignUp